import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";

interface ContactSelectorsProps {
  formData: {
    selectedIndustries: string[];
    tradeShowId: number | null;
  };
  industries: any[];
  tradeShows: any[];
  onChange: (field: string, value: any) => void;
}

const ContactSelectors = ({ formData, industries, tradeShows, onChange }: ContactSelectorsProps) => {
  const handleIndustryToggle = (industryName: string) => {
    const currentIndustries = formData.selectedIndustries || [];
    const updatedIndustries = currentIndustries.includes(industryName)
      ? currentIndustries.filter(i => i !== industryName)
      : [...currentIndustries, industryName];
    onChange("selectedIndustries", updatedIndustries);
  };

  return (
    <>
      <div className="space-y-2">
        <Label>Industries</Label>
        <div className="border rounded-md">
          <ScrollArea className="h-[120px] px-2 py-2">
            <div className="space-y-2">
              {industries.map((industry) => (
                <div key={industry.id} className="flex items-center space-x-2">
                  <Checkbox
                    id={`industry-${industry.id}`}
                    checked={formData.selectedIndustries?.includes(industry.name)}
                    onCheckedChange={() => handleIndustryToggle(industry.name)}
                  />
                  <label
                    htmlFor={`industry-${industry.id}`}
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {industry.name}
                  </label>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>

      <div>
        <Label htmlFor="tradeShow">Trade Show</Label>
        <Select
          value={formData.tradeShowId?.toString() || ""}
          onValueChange={(value) => onChange("tradeShowId", value ? parseInt(value) : null)}
        >
          <SelectTrigger id="tradeShow" className="h-8">
            <SelectValue placeholder="Select trade show" />
          </SelectTrigger>
          <SelectContent>
            {tradeShows.map((show) => (
              <SelectItem key={show.id} value={show.id.toString()}>
                {show.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};

export default ContactSelectors;