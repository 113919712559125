import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FollowUp, Priority, Status } from "@/types/followUp";
import { actionOptions, priorityOptions, statusOptions } from "@/utils/followUpUtils";
import { useToast } from "@/hooks/use-toast";

interface FollowUpFormProps {
  onSave: (followUp: Omit<FollowUp, 'id'>) => Promise<void>;
  onCancel: () => void;
  editingFollowUp: FollowUp | null;
}

const FollowUpForm = ({ onSave, onCancel, editingFollowUp }: FollowUpFormProps) => {
  const { toast } = useToast();
  const [formData, setFormData] = useState<Omit<FollowUp, 'id'>>({
    date: '',
    description: '',
    next_step: '',
    action: '',
    status: 'pending',
    priority: 'medium',
    custom_status: null,
    created_at: new Date().toISOString(),
    user_id: '',
    client_id: 0,
    next_step_date: null,
    next_step_week: null
  });

  useEffect(() => {
    if (editingFollowUp) {
      setFormData({
        date: editingFollowUp.date,
        description: editingFollowUp.description,
        next_step: editingFollowUp.next_step,
        action: editingFollowUp.action,
        status: editingFollowUp.status,
        priority: editingFollowUp.priority,
        custom_status: editingFollowUp.custom_status || null,
        created_at: editingFollowUp.created_at,
        user_id: editingFollowUp.user_id,
        client_id: editingFollowUp.client_id,
        next_step_date: editingFollowUp.next_step_date,
        next_step_week: editingFollowUp.next_step_week
      });
    }
  }, [editingFollowUp]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Calculate week number if next_step_date is provided
      if (formData.next_step_date) {
        const date = new Date(formData.next_step_date);
        const startDate = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil(days / 7);
        formData.next_step_week = weekNumber;
      }

      await onSave(formData);
      
      toast({
        title: "Success",
        description: `Follow-up ${editingFollowUp ? 'updated' : 'added'} successfully`,
      });
    } catch (error) {
      console.error('Error saving follow-up:', error);
      toast({
        title: "Error",
        description: "Failed to save follow-up. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Date</label>
        <Input
          type="date"
          value={formData.date}
          onChange={(e) => {
            const selectedDate = e.target.value;
            setFormData({ ...formData, date: selectedDate });
          }}
          required
          className="h-8 text-sm"
        />
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Action Type</label>
        <Select
          value={formData.action}
          onValueChange={(value) =>
            setFormData({ ...formData, action: value })
          }
        >
          <SelectTrigger className="h-8 text-sm">
            <SelectValue placeholder="Select action type" />
          </SelectTrigger>
          <SelectContent>
            {actionOptions.map((action) => (
              <SelectItem key={action} value={action}>
                {action}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Description</label>
        <Input
          type="text"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          placeholder="Enter description"
          required
          className="h-8 text-sm"
        />
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Next Step</label>
        <Input
          type="text"
          value={formData.next_step}
          onChange={(e) =>
            setFormData({ ...formData, next_step: e.target.value })
          }
          placeholder="Enter next step"
          required
          className="h-8 text-sm"
        />
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Next Step Timing</label>
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <Input
              type="date"
              value={formData.next_step_date || ''}
              onChange={(e) =>
                setFormData({ ...formData, next_step_date: e.target.value })
              }
              placeholder="Select next step date"
              className="h-8 text-sm"
            />
          </div>
          <span className="text-xs font-medium text-muted-foreground">or</span>
          <div className="flex-1">
            <Input
              type="number"
              min="1"
              max="52"
              value={formData.next_step_week || ''}
              onChange={(e) =>
                setFormData({ ...formData, next_step_week: parseInt(e.target.value) || null })
              }
              placeholder="Enter week number"
              className="h-8 text-sm"
            />
          </div>
        </div>
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Status</label>
        <Select
          value={formData.status}
          onValueChange={(value: Status) =>
            setFormData({ ...formData, status: value })
          }
        >
          <SelectTrigger className="h-8 text-sm">
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            {statusOptions.map((status) => (
              <SelectItem key={status} value={status}>
                {status}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {formData.status === 'other' && (
          <Input
            type="text"
            value={formData.custom_status || ''}
            onChange={(e) =>
              setFormData({ ...formData, custom_status: e.target.value })
            }
            placeholder="Enter custom status"
            className="mt-1.5 h-8 text-sm"
          />
        )}
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Priority</label>
        <Select
          value={formData.priority}
          onValueChange={(value: Priority) =>
            setFormData({ ...formData, priority: value })
          }
        >
          <SelectTrigger className="h-8 text-sm">
            <SelectValue placeholder="Select priority" />
          </SelectTrigger>
          <SelectContent>
            {priorityOptions.map((priority) => (
              <SelectItem key={priority} value={priority}>
                {priority}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex gap-2 justify-end">
        <Button type="button" variant="outline" onClick={onCancel} className="h-8 text-sm">
          Cancel
        </Button>
        <Button type="submit" className="h-8 text-sm">
          {editingFollowUp ? 'Update Follow-up' : 'Add Follow-up'}
        </Button>
      </div>
    </form>
  );
};

export default FollowUpForm;