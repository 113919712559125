import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Bell, Calendar } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

interface Remind {
  id: string;
  next_step: string;
  next_step_date: string | null;
  next_step_week: number | null;
  client_name: string;
  status: string;
  industry: string | null;
  follow_up_id: string | null;
  client_id: number | null;
}

interface RemindsPanelProps {
  selectedIndustry?: string;
  onClientSelect?: (clientId: number) => void;
}

const RemindsPanel = ({ selectedIndustry, onClientSelect }: RemindsPanelProps) => {
  const [reminds, setReminds] = useState<Remind[]>([]);
  const { toast } = useToast();

  const fetchReminds = async () => {
    if (!selectedIndustry) {
      setReminds([]);
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      let query = supabase
        .from('reminds')
        .select(`
          *,
          follow_ups!inner (
            client_id
          )
        `)
        .eq('status', 'pending')
        .eq('industry', selectedIndustry)
        .order('created_at', { ascending: false });

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching reminds:', error);
        toast({
          title: "Error",
          description: "Failed to fetch reminds",
          variant: "destructive",
        });
        return;
      }

      if (data) {
        console.log('Fetched reminds:', data);
        const remindsWithClientId = data.map(remind => ({
          ...remind,
          client_id: remind.follow_ups?.client_id || null
        }));
        setReminds(remindsWithClientId);
      }
    } catch (error) {
      console.error('Error in fetchReminds:', error);
      toast({
        title: "Error",
        description: "Failed to fetch reminds",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    console.log('Selected industry changed:', selectedIndustry);
    fetchReminds();

    const channel = supabase
      .channel('reminds-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'reminds'
        },
        (payload) => {
          console.log('Reminds changed:', payload);
          fetchReminds();
        }
      )
      .subscribe((status) => {
        console.log('Subscription status:', status);
      });

    return () => {
      supabase.removeChannel(channel);
    };
  }, [selectedIndustry]);

  const handleRemindClick = (remind: Remind) => {
    if (remind.client_id && onClientSelect) {
      onClientSelect(remind.client_id);
    }
  };

  const formatWhen = (remind: Remind) => {
    if (remind.next_step_date) {
      return new Date(remind.next_step_date).toLocaleDateString();
    }
    if (remind.next_step_week) {
      return `Week ${remind.next_step_week}`;
    }
    return "-";
  };

  const truncateNextStep = (text: string) => {
    if (text.length <= 18) return text;
    return text.slice(0, 18) + "...";
  };

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50/80 border-b py-2">
        <div className="flex items-center gap-2">
          <Bell className="w-5 h-5 text-gray-500" />
          <span className="panel-title">Reminders</span>
          {selectedIndustry && (
            <span className="text-xs text-gray-500">({selectedIndustry})</span>
          )}
        </div>
      </CardHeader>
      <CardContent className="p-2">
        <div className="space-y-1">
          {!selectedIndustry ? (
            <p className="text-xs text-gray-500 text-center py-2">Select an industry to view reminders</p>
          ) : reminds.length === 0 ? (
            <p className="text-xs text-gray-500 text-center py-2">No reminders to display</p>
          ) : (
            <>
              {reminds.map((remind) => (
                <div
                  key={remind.id}
                  className="py-0.5 px-2 hover:bg-gray-50 rounded-sm relative pl-4"
                >
                  <div className="absolute left-1 top-[0.35rem] w-2.5 h-2.5 rounded-full bg-yellow-400" />
                  <div className="grid grid-cols-[1fr,2fr,1fr] gap-2 items-baseline">
                    <span className="text-xs text-gray-600 truncate">
                      {remind.client_name}
                    </span>
                    <div className="flex items-start gap-1">
                      {remind.client_id ? (
                        <button 
                          onClick={() => handleRemindClick(remind)}
                          className="text-xs text-gray-600 leading-none hover:underline text-left"
                          title={remind.next_step}
                        >
                          {truncateNextStep(remind.next_step)}
                        </button>
                      ) : (
                        <p 
                          className="text-xs text-gray-600 leading-none text-left"
                          title={remind.next_step}
                        >
                          {truncateNextStep(remind.next_step)}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center gap-1 justify-end">
                      <Calendar className="w-3 h-3 text-gray-400" />
                      <span className="text-xs text-gray-600 leading-none">
                        {formatWhen(remind)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default RemindsPanel;