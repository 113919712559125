import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface TradeShowBasicInfoProps {
  name: string;
  startDate: string;
  endDate: string;
  location: string;
  onNameChange: (value: string) => void;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
  onLocationChange: (value: string) => void;
}

const TradeShowBasicInfo = ({
  name,
  startDate,
  endDate,
  location,
  onNameChange,
  onStartDateChange,
  onEndDateChange,
  onLocationChange,
}: TradeShowBasicInfoProps) => {
  return (
    <>
      <div>
        <Label htmlFor="name">Show Name</Label>
        <Input
          id="name"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          placeholder="Enter show name"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label htmlFor="startDate">Start Date</Label>
          <Input
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => onStartDateChange(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="endDate">End Date</Label>
          <Input
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => onEndDateChange(e.target.value)}
            min={startDate}
          />
        </div>
      </div>

      <div>
        <Label htmlFor="location">Location</Label>
        <Input
          id="location"
          value={location}
          onChange={(e) => onLocationChange(e.target.value)}
          placeholder="Enter city"
        />
      </div>
    </>
  );
};

export default TradeShowBasicInfo;