import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { EventStatus } from "@/types/calendar";

export const useCalendarMutations = () => {
  const { toast } = useToast();

  const addEvent = async (weekNum: number, day: string, eventText: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Error",
          description: "You must be logged in to add events",
          variant: "destructive",
        });
        return false;
      }

      // Add a placeholder event to ensure the week is maintained
      const { error } = await supabase
        .from('calendar_events')
        .insert({
          week: weekNum,
          day,
          text: eventText,
          status: "pending",
          user_id: user.id
        });

      if (error) {
        console.error('Error inserting event:', error);
        toast({
          title: "Error",
          description: "Failed to add event",
          variant: "destructive",
        });
        return false;
      }

      toast({
        title: "Success",
        description: "Event added successfully",
      });
      return true;
    } catch (error) {
      console.error('Error in addEvent:', error);
      return false;
    }
  };

  const deleteEvent = async (week: number, day: string, text: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('calendar_events')
        .delete()
        .eq('user_id', user.id)
        .eq('week', week)
        .eq('day', day)
        .eq('text', text);

      if (error) {
        console.error('Error deleting event:', error);
        toast({
          title: "Error",
          description: "Failed to delete event",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Success",
        description: "Event deleted successfully",
      });
    } catch (error) {
      console.error('Error in deleteEvent:', error);
    }
  };

  const updateEventStatus = async (week: number, day: string, text: string, currentStatus: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const statusMap: Record<string, string> = {
        'gray': 'done',
        'done': 'in-process',
        'in-process': 'pending',
        'pending': 'gray'
      };

      const newStatus = statusMap[currentStatus] || 'pending';

      const { error } = await supabase
        .from('calendar_events')
        .update({ status: newStatus })
        .eq('user_id', user.id)
        .eq('week', week)
        .eq('day', day)
        .eq('text', text);

      if (error) {
        console.error('Error updating event status:', error);
        toast({
          title: "Error",
          description: "Failed to update event status",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Success",
        description: "Status updated successfully",
      });
    } catch (error) {
      console.error('Error in updateEventStatus:', error);
    }
  };

  return {
    addEvent,
    deleteEvent,
    updateEventStatus
  };
};