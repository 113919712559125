import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";

interface Company {
  id: number;
  company: string;
  contact?: string | null;
}

interface CompanySelectorProps {
  companies: Company[];
  selectedCompanies: number[];
  onCompanyToggle: (companyId: number) => void;
}

const CompanySelector = ({
  companies,
  selectedCompanies,
  onCompanyToggle,
}: CompanySelectorProps) => {
  return (
    <div className="space-y-2">
      <label className="text-sm font-medium">Select Companies</label>
      {companies.length > 0 ? (
        <ScrollArea className="h-[200px] w-full border rounded-md p-4">
          <div className="space-y-2">
            {companies.map((company) => (
              <div key={company.id} className="flex items-center space-x-2">
                <Checkbox
                  id={`company-${company.id}`}
                  checked={selectedCompanies.includes(company.id)}
                  onCheckedChange={() => onCompanyToggle(company.id)}
                />
                <label
                  htmlFor={`company-${company.id}`}
                  className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
                >
                  <div>{company.company}</div>
                  {company.contact && (
                    <div className="text-xs text-gray-500">{company.contact}</div>
                  )}
                </label>
              </div>
            ))}
          </div>
        </ScrollArea>
      ) : (
        <div className="text-sm text-gray-500 p-4 border rounded-md">
          No companies available for the selected category and industry
        </div>
      )}
    </div>
  );
};

export default CompanySelector;