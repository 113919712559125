import React from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

interface RegistrationFormProps {
  formData: {
    username: string;
    email: string;
    passcode: string;
    confirmPasscode: string;
  };
  loading?: boolean;
  handleChange: (field: string, value: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
}

export const RegistrationForm = ({
  formData,
  loading = false,
  handleChange,
  handleSubmit,
}: RegistrationFormProps) => {
  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="max-w-md w-full">
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold">Complete Your Registration</h1>
          <p className="text-muted-foreground mt-2">
            Set up your account to get started
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              value={formData.username}
              onChange={(e) => handleChange("username", e.target.value)}
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              required
              readOnly
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="passcode">Passcode</Label>
            <Input
              id="passcode"
              type="password"
              value={formData.passcode}
              onChange={(e) => handleChange("passcode", e.target.value)}
              required
              minLength={6}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="confirmPasscode">Confirm Passcode</Label>
            <Input
              id="confirmPasscode"
              type="password"
              value={formData.confirmPasscode}
              onChange={(e) => handleChange("confirmPasscode", e.target.value)}
              required
              minLength={6}
            />
          </div>

          <Button type="submit" className="w-full" disabled={loading}>
            {loading ? "Setting up..." : "Complete Setup"}
          </Button>
        </form>
      </div>
    </div>
  );
};