import { CompanyProfileEditor } from "@/components/profile/CompanyProfileEditor";

const Profile = () => {
  return (
    <div className="container mx-auto py-8">
      <CompanyProfileEditor />
    </div>
  );
};

export default Profile;