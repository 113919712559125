import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { TradeShow } from "@/types/tradeShow";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface TradeShowCardProps {
  show: TradeShow;
  onDelete: (id: number) => void;
  onTypeChange: (id: number, newType: 'exhibiting' | 'visiting') => void;
}

const TradeShowCard = ({ show, onDelete, onTypeChange }: TradeShowCardProps) => {
  const { toast } = useToast();

  const formatDateRange = (dateString: string) => {
    const [startDate, endDate] = dateString.split(' to ').map(date => {
      const [year, month, day] = date.split('-');
      return { year, month, day };
    });

    if (startDate.year === endDate.year) {
      return `${startDate.year}  ${startDate.month}-${startDate.day} to ${endDate.month}-${endDate.day}`;
    }

    return `${startDate.year}-${startDate.month}-${startDate.day} to ${endDate.year}-${endDate.month}-${endDate.day}`;
  };

  const handleDelete = async () => {
    try {
      console.log('Starting deletion process for trade show:', show.id);

      // First, delete related trade show industries
      const { error: industriesError } = await supabase
        .from('trade_show_industries')
        .delete()
        .eq('trade_show_id', show.id);

      if (industriesError) {
        console.error('Error deleting trade show industries:', industriesError);
        throw industriesError;
      }

      // Delete related trade show contacts
      const { error: contactsError } = await supabase
        .from('trade_show_contacts')
        .delete()
        .eq('trade_show_id', show.id);

      if (contactsError) {
        console.error('Error deleting trade show contacts:', contactsError);
        throw contactsError;
      }

      // Delete related trade show leads
      const { error: leadsError } = await supabase
        .from('trade_show_leads')
        .delete()
        .eq('trade_show_id', show.id);

      if (leadsError) {
        console.error('Error deleting trade show leads:', leadsError);
        throw leadsError;
      }

      // Finally, delete the trade show itself
      const { error: tradeShowError } = await supabase
        .from('trade_shows')
        .delete()
        .eq('id', show.id);

      if (tradeShowError) {
        console.error('Error deleting trade show:', tradeShowError);
        throw tradeShowError;
      }

      console.log('Trade show and related data deleted successfully');
      
      toast({
        title: "Success",
        description: "Trade show deleted successfully",
      });
      
      onDelete(show.id);
    } catch (error: any) {
      console.error('Error in deletion process:', error);
      toast({
        title: "Error",
        description: "Failed to delete trade show. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleTypeChange = async () => {
    const newType = show.type === 'exhibiting' ? 'visiting' : 'exhibiting';
    
    try {
      const { error } = await supabase
        .from('trade_shows')
        .update({ type: newType })
        .eq('id', show.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Trade show type updated successfully",
      });
      
      onTypeChange(show.id, newType);
    } catch (error: any) {
      console.error('Error updating trade show type:', error);
      toast({
        title: "Error",
        description: "Failed to update trade show type",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-3 hover:bg-gray-50 relative">
      <div className="absolute top-3 right-3">
        <span
          onClick={handleTypeChange}
          className={`text-xs px-2 py-1 rounded-full cursor-pointer transition-colors ${
            show.type === 'exhibiting' 
              ? 'bg-blue-100 text-blue-800 hover:bg-blue-200' 
              : 'bg-green-100 text-green-800 hover:bg-green-200'
          }`}
        >
          {show.type}
        </span>
      </div>
      <div className="pr-20">
        <h3 className="font-medium text-sm">{show.name}</h3>
        <p className="text-xs text-gray-500">{formatDateRange(show.date)}</p>
        {show.city && (
          <p className="text-xs text-gray-500">{show.city}</p>
        )}
      </div>
      <div className="absolute bottom-3 right-3">
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 hover:bg-red-100 hover:text-red-600"
            >
              <Trash2 className="h-3 w-3 text-gray-500" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This will permanently delete the trade show and all associated data including contacts, leads, and industry associations.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete} className="bg-red-600 hover:bg-red-700">
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </Card>
  );
};

export default TradeShowCard;