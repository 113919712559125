import { useState, useEffect } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface Note {
  id: number;
  note_text: string;
  created_at: string;
}

interface ProspectNotesProps {
  prospectId: number;
}

const ProspectNotes = ({ prospectId }: ProspectNotesProps) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState("");
  const { toast } = useToast();

  const fetchNotes = async () => {
    const { data, error } = await supabase
      .from('prospect_notes')
      .select('*')
      .eq('prospect_id', prospectId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching notes:', error);
      return;
    }

    setNotes(data || []);
  };

  useEffect(() => {
    fetchNotes();
  }, [prospectId]);

  const handleAddNote = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const { error } = await supabase
      .from('prospect_notes')
      .insert([{
        prospect_id: prospectId,
        note_text: newNote.trim(),
        user_id: user.id,
      }]);

    if (error) {
      toast({
        title: "Error",
        description: "Failed to add note. Please try again.",
        variant: "destructive",
      });
      return;
    }

    setNewNote("");
    fetchNotes();
  };

  const handleDeleteNote = async (noteId: number) => {
    const { error } = await supabase
      .from('prospect_notes')
      .delete()
      .eq('id', noteId);

    if (error) {
      toast({
        title: "Error",
        description: "Failed to delete note. Please try again.",
        variant: "destructive",
      });
      return;
    }

    fetchNotes();
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleAddNote}>
        <Textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Add a note..."
          className="mb-2"
        />
        <Button type="submit" disabled={!newNote.trim()}>
          Add Note
        </Button>
      </form>
      <div className="space-y-4">
        {notes.map((note) => (
          <div
            key={note.id}
            className="bg-gray-50 rounded-lg p-4 flex justify-between items-start"
          >
            <div>
              <p className="text-sm text-gray-900 whitespace-pre-wrap">
                {note.note_text}
              </p>
              <span className="text-xs text-gray-500">
                {format(new Date(note.created_at), "MMM d, yyyy h:mm a")}
              </span>
            </div>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => handleDeleteNote(note.id)}
              className="h-8 w-8"
            >
              <Trash2 className="h-4 w-4 text-gray-500 hover:text-red-500" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProspectNotes;