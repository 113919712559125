import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        console.log("No session found, redirecting to login");
        toast({
          title: "Authentication required",
          description: "Please login to access this page",
          variant: "destructive",
        });
        navigate("/login");
        return;
      }

      // Admin user has full access
      if (session.user.email === 'jstaack@staackllc.com') {
        console.log("Admin user detected, allowing access");
        return;
      }

      // Check profile status
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('subscription_status, payment_completed')
        .eq('id', session.user.id)
        .single();

      if (profileError) {
        console.error("Error checking profile:", profileError);
        navigate("/plans");
        return;
      }

      // If no active subscription, redirect to plans
      if (!profile || profile.subscription_status !== 'active') {
        console.log("No active subscription, redirecting to plans");
        toast({
          title: "Subscription Required",
          description: "Please subscribe to access this feature",
          variant: "destructive",
        });
        navigate("/plans");
        return;
      }

      console.log("Access granted: Active subscription verified");
    };

    checkAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT' || !session) {
        navigate("/login");
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate, toast]);

  return <>{children}</>;
};

export default ProtectedRoute;