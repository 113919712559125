import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

const CreateInitialUser = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const createUser = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.auth.signUp({
        email: 'jstaack@staackllc.com',
        password: '@Sb2025',
        options: {
          data: {
            username: 'jstaack',
          }
        }
      });

      if (error) throw error;

      // Update profile with admin status
      const { error: profileError } = await supabase
        .from('profiles')
        .update({
          is_admin: true,
          subscription_status: 'active'
        })
        .eq('id', data.user?.id);

      if (profileError) throw profileError;

      toast({
        title: "Success!",
        description: "Admin user account created successfully. You can now log in.",
      });

      navigate("/login");
    } catch (error: any) {
      console.error('Error creating user:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background flex items-center justify-center">
      <div className="max-w-md w-full p-6 space-y-6 bg-card rounded-lg shadow-lg">
        <div className="text-center">
          <h1 className="text-2xl font-bold">Create Admin Account</h1>
          <p className="text-muted-foreground mt-2">
            This will create the initial admin user account
          </p>
        </div>

        <Button 
          onClick={createUser} 
          className="w-full" 
          disabled={loading}
        >
          {loading ? "Creating..." : "Create Admin Account"}
        </Button>
      </div>
    </div>
  );
};

export default CreateInitialUser;