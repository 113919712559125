import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import ContactNote from "./ContactNote";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

interface Note {
  id: number;
  note_text: string;
  created_at: string;
}

interface ContactNotesListProps {
  contactId: number;
  notes: Note[];
  onNotesChange: () => void;
}

const ContactNotesList = ({ contactId, notes, onNotesChange }: ContactNotesListProps) => {
  const [newNote, setNewNote] = useState("");
  const { toast } = useToast();

  const handleAddNote = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && newNote.trim()) {
      e.preventDefault();
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from("trade_show_contact_notes")
        .insert({
          contact_id: contactId,
          note_text: newNote.trim(),
          user_id: user.id,
        });

      if (error) {
        toast({
          title: "Error",
          description: "Failed to add note",
          variant: "destructive",
        });
        return;
      }

      setNewNote("");
      onNotesChange();
    }
  };

  const handleDeleteNote = async (noteId: number) => {
    const { error } = await supabase
      .from("trade_show_contact_notes")
      .delete()
      .eq("id", noteId);

    if (error) {
      toast({
        title: "Error",
        description: "Failed to delete note",
        variant: "destructive",
      });
      return;
    }

    onNotesChange();
  };

  return (
    <div className="space-y-2">
      <Textarea
        placeholder="Add a note... (Press Enter to save)"
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
        onKeyDown={handleAddNote}
        className="min-h-[60px] text-sm"
      />
      <div className="space-y-2">
        {notes.map((note) => (
          <ContactNote
            key={note.id}
            id={note.id}
            noteText={note.note_text}
            createdAt={note.created_at}
            onDelete={handleDeleteNote}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactNotesList;