import { Checkbox } from "@/components/ui/checkbox";

interface DisclaimerCheckboxProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export const DisclaimerCheckbox = ({ checked, onCheckedChange }: DisclaimerCheckboxProps) => {
  return (
    <div className="space-y-4">
      <div className="text-xs text-gray-500 max-h-48 overflow-y-auto border rounded-lg p-4">
        <h4 className="font-medium mb-2 text-sm">Disclaimer</h4>
        
        <h5 className="font-medium mt-3 text-xs">Data Usage and Privacy</h5>
        <p className="mb-2">Salesby does not access, use, share, sell, or distribute any data saved by users on the platform. All information entered into the system remains solely under the user's control and responsibility. Users are advised to manage their data responsibly.</p>
        
        <h5 className="font-medium mt-3 text-xs">Use at Your Own Risk</h5>
        <p className="mb-2">The use of the Salesby platform is entirely at the user's own risk. Salesby provides the platform "as is" and makes no warranties, express or implied, regarding its performance, reliability, or fitness for any particular purpose. Users are responsible for ensuring the platform meets their specific needs.</p>
        
        <h5 className="font-medium mt-3 text-xs">Platform-Only Service</h5>
        <p className="mb-2">Salesby acts solely as a service provider for data management. The platform does not verify, validate, or provide any data. Users are solely responsible for the accuracy, completeness, and reliability of any data input into the system.</p>
        
        <h5 className="font-medium mt-3 text-xs">Membership and Data Retention</h5>
        <p className="mb-2">Continued access to the platform and stored data requires an active and paid membership. Failure to maintain payment or renew the subscription may result in the suspension or permanent deletion of stored data. Salesby is not liable for any data loss due to non-payment or account inactivity.</p>
        
        <h5 className="font-medium mt-3 text-xs">No Backups or Downloads</h5>
        <p className="mb-2">Salesby does not provide downloadable versions of stored data or backup services. Users are encouraged to maintain their own independent backups of any critical information. Salesby is not liable for any consequences resulting from the absence of such backups.</p>
        
        <h5 className="font-medium mt-3 text-xs">System Modifications and Updates</h5>
        <p className="mb-2">Salesby reserves the right to modify, update, or discontinue the platform, its features, or services at any time without prior notice. It is the user's responsibility to stay informed of such changes by reviewing the terms and updates regularly.</p>
        
        <h5 className="font-medium mt-3 text-xs">Limitation of Liability</h5>
        <p className="mb-2">Salesby shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use, inability to use, or reliance on the platform. This includes, but is not limited to, data loss, business interruptions, financial losses, or technical issues.</p>
        
        <h5 className="font-medium mt-3 text-xs">Third-Party Integrations</h5>
        <p className="mb-2">The platform may integrate with or rely on third-party services (e.g., Supabase) for functionality. Salesby is not responsible for the performance, security, or reliability of these third-party services. Users are subject to the terms and policies of these third-party providers.</p>
        
        <h5 className="font-medium mt-3 text-xs">Prohibited Uses</h5>
        <p className="mb-2">Users are prohibited from using the platform for unlawful activities, data scraping, reverse engineering, or any actions that compromise the platform's security or integrity. Violations may result in immediate termination of access and possible legal action.</p>
        
        <h5 className="font-medium mt-3 text-xs">Intellectual Property</h5>
        <p className="mb-2">All intellectual property rights related to the platform, including but not limited to the software, design, trademarks, and logos, are owned by Salesby. Unauthorized use, reproduction, or distribution of any intellectual property is strictly prohibited.</p>
        
        <h5 className="font-medium mt-3 text-xs">User Responsibility</h5>
        <p className="mb-2">Users are responsible for safeguarding their account credentials and ensuring unauthorized parties do not gain access. Salesby is not liable for any breaches, losses, or damages caused by user negligence or failure to secure account information.</p>
        
        <h5 className="font-medium mt-3 text-xs">Force Majeure</h5>
        <p className="mb-2">Salesby shall not be held liable for any failure or delay in performance due to circumstances beyond its control, including but not limited to natural disasters, acts of war, cyberattacks, or government actions.</p>
        
        <h5 className="font-medium mt-3 text-xs">Indemnification</h5>
        <p className="mb-2">Users agree to indemnify and hold harmless Salesby, its affiliates, and its employees from any claims, damages, or liabilities arising from the misuse of the platform or violations of these terms.</p>
        
        <h5 className="font-medium mt-3 text-xs">Governing Law and Jurisdiction</h5>
        <p className="mb-2">This disclaimer and all related matters are governed by the laws of United States of America, State of New York. Any disputes arising from the use of the platform shall be resolved exclusively in the courts of New York City.</p>
        
        <h5 className="font-medium mt-3 text-xs">Acceptance of Terms</h5>
        <p className="mb-2">By subscribing to and using the Salesby platform, users confirm that they have read, understood, and agreed to this disclaimer, along with the Terms of Service and Privacy Policy. If you do not agree with these terms, you must refrain from using the platform.</p>
      </div>
      
      <div className="flex items-center space-x-2">
        <Checkbox 
          id="disclaimer" 
          checked={checked}
          onCheckedChange={onCheckedChange}
          className="border-gray-300"
        />
        <label htmlFor="disclaimer" className="text-sm text-gray-600">
          I have read and agree to the disclaimer
        </label>
      </div>
    </div>
  );
};