import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { UserCircle } from "lucide-react";
import { TradeShowContact } from "@/types/tradeShow";
import { supabase } from "@/integrations/supabase/client";
import ContactBasicDetails from "../contacts/info/ContactBasicDetails";
import ContactNotesList from "../contacts/notes/ContactNotesList";

interface ContactInfoPanelProps {
  selectedContact: TradeShowContact | null;
}

interface Note {
  id: number;
  note_text: string;
  created_at: string;
}

const ContactInfoPanel = ({ selectedContact }: ContactInfoPanelProps) => {
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    if (selectedContact) {
      fetchNotes();
    }
  }, [selectedContact]);

  const fetchNotes = async () => {
    if (!selectedContact) return;

    const { data, error } = await supabase
      .from("trade_show_contact_notes")
      .select("*")
      .eq("contact_id", selectedContact.id)
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching notes:", error);
      return;
    }

    setNotes(data || []);
  };

  if (!selectedContact) {
    return (
      <Card className="h-full bg-gradient-to-br from-gray-50 to-white">
        <CardHeader className="bg-white/50 border-b pb-3">
          <div className="flex items-center gap-2">
            <UserCircle className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-semibold text-gray-800">Contact Information</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="flex items-center justify-center h-[calc(100%-4rem)]">
          <div className="text-center space-y-2">
            <UserCircle className="w-12 h-12 text-gray-300 mx-auto" />
            <p className="text-gray-500 text-sm">Select a contact to view details</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="h-full bg-gradient-to-br from-gray-50 to-white">
      <CardHeader className="bg-white/50 border-b pb-3">
        <div className="flex items-center gap-2">
          <UserCircle className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-semibold text-gray-800">Contact Information</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-4 space-y-4">
        <ContactBasicDetails contact={selectedContact} />
        <div className="pt-2">
          <ContactNotesList 
            contactId={selectedContact.id} 
            notes={notes} 
            onNotesChange={fetchNotes}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default ContactInfoPanel;