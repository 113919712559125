import { useState, useEffect } from "react";
import { CalendarEvent, EventStatus } from "@/types/calendar";
import { useCalendarQueries } from "./calendar/useCalendarQueries";
import { useCalendarMutations } from "./calendar/useCalendarMutations";
import { supabase } from "@/integrations/supabase/client";

export const useCalendarEvents = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [activeWeeks, setActiveWeeks] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const { loadEvents } = useCalendarQueries();
  const { addEvent, deleteEvent, updateEventStatus } = useCalendarMutations();

  const refreshEvents = async () => {
    const calendarEvents = await loadEvents();
    if (calendarEvents) {
      const typedEvents: CalendarEvent[] = calendarEvents.map(event => ({
        ...event,
        status: event.status as EventStatus
      }));
      setEvents(typedEvents);
      
      // Get unique weeks from events and sort them
      const weeksFromEvents = [...new Set(typedEvents.map(event => event.week))];
      setActiveWeeks(weeksFromEvents.sort((a, b) => a - b));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    refreshEvents();

    // Subscribe to calendar events changes
    const channel = supabase
      .channel('calendar-events-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'calendar_events'
        },
        () => {
          refreshEvents();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return {
    events,
    activeWeeks,
    isLoading,
    addEvent,
    deleteEvent,
    updateEventStatus
  };
};