import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { User } from "lucide-react";
import { Client } from "@/types/client";
import { formatPhoneNumber } from "@/lib/formatPhone";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";

interface CompanyContact {
  id: number;
  name: string;
  phone: string | null;
  mobile: string | null;
  email: string | null;
}

interface ClientInfoPanelProps {
  selectedClient: Client | null;
}

const ClientInfoPanel = ({ selectedClient }: ClientInfoPanelProps) => {
  const [additionalContacts, setAdditionalContacts] = useState<CompanyContact[]>([]);
  const [currentClient, setCurrentClient] = useState<Client | null>(selectedClient);

  useEffect(() => {
    setCurrentClient(selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    if (currentClient) {
      fetchAdditionalContacts();
    } else {
      setAdditionalContacts([]);
    }
  }, [currentClient]);

  // Set up real-time subscription for client updates
  useEffect(() => {
    const setupRealtimeSubscription = async () => {
      if (!currentClient) return;

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const channel = supabase
        .channel('client-info-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'clients',
            filter: `id=eq.${currentClient.id}`
          },
          (payload) => {
            console.log('Client info real-time update received:', payload);
            
            if (payload.eventType === 'UPDATE') {
              setCurrentClient(payload.new as Client);
            } else if (payload.eventType === 'DELETE') {
              setCurrentClient(null);
            }
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    };

    setupRealtimeSubscription();
  }, [currentClient?.id]);

  // Set up real-time subscription for company contacts
  useEffect(() => {
    const setupContactsRealtimeSubscription = async () => {
      if (!currentClient) return;

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const channel = supabase
        .channel('company-contacts-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'company_contacts',
            filter: `client_id=eq.${currentClient.id}`
          },
          (payload) => {
            console.log('Company contacts real-time update received:', payload);
            fetchAdditionalContacts();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    };

    setupContactsRealtimeSubscription();
  }, [currentClient?.id]);

  const fetchAdditionalContacts = async () => {
    if (!currentClient) return;

    try {
      const { data, error } = await supabase
        .from('company_contacts')
        .select('*')
        .eq('client_id', currentClient.id);

      if (error) throw error;
      setAdditionalContacts(data || []);
    } catch (error) {
      console.error('Error fetching additional contacts:', error);
    }
  };

  if (!currentClient) {
    return (
      <Card className="h-fit relative border shadow-sm">
        <CardHeader className="bg-gray-50/80 border-b p-3">
          <div className="flex items-center gap-2">
            <User className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Client Info</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-3">
          <p className="text-sm text-gray-500">Select a client to view details</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50/80 border-b p-3">
        <div className="flex items-center gap-2">
          <User className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-medium text-gray-700">Client Info</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-3">
        <div className="space-y-4">
          {/* Primary Contact */}
          <div className="space-y-1.5">
            <p className="text-xs text-gray-600 font-bold">{currentClient.company}</p>
            <p className="text-xs text-gray-600">{currentClient.contact}</p>
            <a 
              href={`mailto:${currentClient.email}`} 
              className="text-xs text-gray-600 hover:text-gray-800 hover:underline block"
            >
              {currentClient.email}
            </a>
            <div className="flex gap-4">
              <div className="flex items-center gap-1">
                <span className="text-xs font-medium">P:</span>
                <a 
                  href={`tel:${currentClient.phone}`} 
                  className="text-xs text-gray-600 hover:text-gray-800"
                >
                  {formatPhoneNumber(currentClient.phone)}
                </a>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-xs font-medium">M:</span>
                <a 
                  href={`tel:${currentClient.mobile}`} 
                  className="text-xs text-gray-600 hover:text-gray-800"
                >
                  {formatPhoneNumber(currentClient.mobile)}
                </a>
              </div>
            </div>
          </div>

          {/* Additional Contacts */}
          {additionalContacts.length > 0 && (
            <div className="divide-y divide-gray-100">
              {additionalContacts.map((contact) => (
                <div key={contact.id} className="space-y-1 py-3">
                  <p className="text-xs text-gray-600">{contact.name}</p>
                  {contact.email && (
                    <a 
                      href={`mailto:${contact.email}`} 
                      className="text-xs text-gray-600 hover:text-gray-800 hover:underline block"
                    >
                      {contact.email}
                    </a>
                  )}
                  <div className="flex gap-4">
                    {contact.phone && (
                      <div className="flex items-center gap-1">
                        <span className="text-xs font-medium">P:</span>
                        <a 
                          href={`tel:${contact.phone}`} 
                          className="text-xs text-gray-600 hover:text-gray-800"
                        >
                          {formatPhoneNumber(contact.phone)}
                        </a>
                      </div>
                    )}
                    {contact.mobile && (
                      <div className="flex items-center gap-1">
                        <span className="text-xs font-medium">M:</span>
                        <a 
                          href={`tel:${contact.mobile}`} 
                          className="text-xs text-gray-600 hover:text-gray-800"
                        >
                          {formatPhoneNumber(contact.mobile)}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          
          <div className="space-y-0.5">
            <p className="text-xs text-gray-600">{currentClient.address}</p>
            <p className="text-xs text-gray-600">{currentClient.city}, {currentClient.state} {currentClient.zip}</p>
          </div>

          <a 
            href={currentClient.web?.startsWith('http') ? currentClient.web : `http://${currentClient.web}`}
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-xs text-gray-600 hover:text-gray-800 hover:underline"
          >
            {currentClient.web}
          </a>
          
          <div className="flex items-center gap-1">
            <span className="text-xs font-medium">Ind:</span>
            <p className="text-xs text-gray-600">{currentClient.industry}</p>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-xs font-medium">Note:</span>
            <p className="text-xs text-gray-600">{currentClient.notes}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ClientInfoPanel;