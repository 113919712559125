import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY, STRIPE_MODE } from "@/lib/stripe/config";

export const StripePricingTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setUserEmail(session?.user?.email || null);
      } catch (err) {
        console.error('Session error:', err);
        setError('Failed to load user session');
      }
    };

    getSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUserEmail(session?.user?.email || null);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="text-blue-500 hover:text-blue-600"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!STRIPE_PUBLISHABLE_KEY || !STRIPE_PRICING_TABLE_ID) {
    console.error('Missing Stripe configuration:', { 
      STRIPE_PUBLISHABLE_KEY, 
      STRIPE_PRICING_TABLE_ID,
      mode: STRIPE_MODE 
    });
    return (
      <div className="text-center py-8">
        <p className="text-red-500">Configuration error. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {loading ? (
        <div className="flex justify-center py-8">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      ) : (
        <stripe-pricing-table 
          pricing-table-id={STRIPE_PRICING_TABLE_ID}
          publishable-key={STRIPE_PUBLISHABLE_KEY}
          customer-email={userEmail || ''}
        />
      )}
    </div>
  );
};