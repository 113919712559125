import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface ContactBasicInfoProps {
  formData: {
    company: string;
    contact: string;
    phone: string;
    email: string;
  };
  onChange: (field: string, value: string) => void;
}

const ContactBasicInfo = ({ formData, onChange }: ContactBasicInfoProps) => {
  return (
    <>
      <div className="col-span-2">
        <Label htmlFor="company">Company Name *</Label>
        <Input
          id="company"
          value={formData.company}
          onChange={(e) => onChange("company", e.target.value)}
          required
          className="h-8"
        />
      </div>

      <div className="col-span-2">
        <Label htmlFor="contact">Contact Person</Label>
        <Input
          id="contact"
          value={formData.contact}
          onChange={(e) => onChange("contact", e.target.value)}
          className="h-8"
        />
      </div>

      <div>
        <Label htmlFor="phone">Phone</Label>
        <Input
          id="phone"
          value={formData.phone}
          onChange={(e) => onChange("phone", e.target.value)}
          className="h-8"
        />
      </div>

      <div>
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          value={formData.email}
          onChange={(e) => onChange("email", e.target.value)}
          className="h-8"
        />
      </div>
    </>
  );
};

export default ContactBasicInfo;