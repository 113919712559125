import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LayoutDashboard, Building2, Hammer, FileText, Settings } from "lucide-react";
import Logo from "@/components/Logo";
import { supabase } from "@/integrations/supabase/client";
import { MobileMenu } from "./header/MobileMenu";
import { UserMenu } from "./header/UserMenu";
import { Button } from "./ui/button";

const Header = () => {
  const [username, setUsername] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getProfile = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setIsLoggedIn(true);
        const { data: profile } = await supabase
          .from('profiles')
          .select('username, is_admin')
          .eq('id', session.user.id)
          .single();
        
        if (profile) {
          setUsername(profile.username);
          setIsAdmin(profile.is_admin || false);
        }
      }
    };

    getProfile();
  }, []);

  return (
    <nav className="bg-white/80 backdrop-blur-md shadow-sm px-4 py-3 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Logo />
        </div>
        
        <div className="flex items-center space-x-4">
          {isLoggedIn ? (
            <>
              <div className="flex items-center space-x-2">
                <Link 
                  to="/dashboard" 
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  title="Dashboard"
                >
                  <LayoutDashboard className="h-5 w-5" />
                </Link>
                
                <Link 
                  to="/trade-shows" 
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  title="Trade Shows"
                >
                  <Building2 className="h-5 w-5" />
                </Link>

                <Link 
                  to="/prospecting" 
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  title="Prospecting"
                >
                  <Hammer className="h-5 w-5" />
                </Link>

                <Link 
                  to="/reports" 
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  title="Reports"
                >
                  <FileText className="h-5 w-5" />
                </Link>

                {isAdmin && (
                  <Link 
                    to="/admin" 
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    title="Admin"
                  >
                    <Settings className="h-5 w-5" />
                  </Link>
                )}
                
                <UserMenu username={username} />
              </div>
              <MobileMenu />
            </>
          ) : (
            <div className="flex items-center space-x-4">
              <Link to="/login">
                <Button variant="ghost">Login</Button>
              </Link>
              <MobileMenu />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;