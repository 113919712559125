import { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Contact, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { TradeShowContact } from "@/types/tradeShow";
import TradeShowContactForm from "../contacts/TradeShowContactForm";
import ContactsList from "./contacts/ContactsList";
import { useContactsData } from "@/hooks/useContactsData";
import PaginationControls from "@/components/PaginationControls";

interface ContactsPanelProps {
  onContactSelect?: (contact: TradeShowContact) => void;
  selectedIndustry?: string | null;
}

const CONTACTS_PER_PAGE = 30;

const ContactsPanel = ({ onContactSelect, selectedIndustry }: ContactsPanelProps) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<TradeShowContact | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    contacts,
    handleDeleteContact,
    fetchContacts
  } = useContactsData(selectedIndustry);

  const handleEditContact = (contact: TradeShowContact) => {
    setSelectedContact(contact);
    setIsEditDialogOpen(true);
  };

  const handleContactClick = (contact: TradeShowContact) => {
    if (onContactSelect) {
      onContactSelect(contact);
    }
  };

  // Calculate pagination
  const totalPages = Math.ceil(contacts.length / CONTACTS_PER_PAGE);
  const paginatedContacts = contacts.slice(
    (currentPage - 1) * CONTACTS_PER_PAGE,
    currentPage * CONTACTS_PER_PAGE
  );

  return (
    <Card className="h-full">
      <CardHeader className="bg-gray-50 border-b pb-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Contact className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">Contacts</CardTitle>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsAddDialogOpen(true)}
            className="h-8 w-8"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-0">
        <ContactsList
          contacts={paginatedContacts}
          onEdit={handleEditContact}
          onDelete={handleDeleteContact}
          onContactClick={handleContactClick}
        />
        {contacts.length > CONTACTS_PER_PAGE && (
          <div className="pb-4">
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </CardContent>

      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Contact</DialogTitle>
          </DialogHeader>
          <TradeShowContactForm 
            onSuccess={() => {
              setIsAddDialogOpen(false);
              fetchContacts();
            }}
            onCancel={() => setIsAddDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Contact</DialogTitle>
          </DialogHeader>
          {selectedContact && (
            <TradeShowContactForm 
              contact={selectedContact}
              onSuccess={() => {
                setIsEditDialogOpen(false);
                fetchContacts();
              }}
              onCancel={() => setIsEditDialogOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default ContactsPanel;