import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface ContactLocationInfoProps {
  formData: {
    city: string;
    state: string;
    country: string;
  };
  onChange: (field: string, value: string) => void;
}

const ContactLocationInfo = ({ formData, onChange }: ContactLocationInfoProps) => {
  return (
    <>
      <div>
        <Label htmlFor="city">City</Label>
        <Input
          id="city"
          value={formData.city}
          onChange={(e) => onChange("city", e.target.value)}
          className="h-8"
        />
      </div>

      <div>
        <Label htmlFor="state">State</Label>
        <Input
          id="state"
          value={formData.state}
          onChange={(e) => onChange("state", e.target.value)}
          className="h-8"
        />
      </div>

      <div className="col-span-2">
        <Label htmlFor="country">Country</Label>
        <Input
          id="country"
          value={formData.country}
          onChange={(e) => onChange("country", e.target.value)}
          className="h-8"
        />
      </div>
    </>
  );
};

export default ContactLocationInfo;