import { Client } from "@/types/client";
import { Button } from "@/components/ui/button";
import { Pencil, Trash2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useState } from "react";
import ClientForm from "./ClientForm";
import ColorPicker from "./ColorPicker";
import DeleteConfirmDialog from "../panels/industries/DeleteConfirmDialog";

interface ClientsListProps {
  clients: Client[];
  selectedClient: Client | null;
  onClientSelect: (client: Client) => void;
  industries: { name: string; color: string; }[];
  onClientDeleted?: () => void;
}

export const ClientsList = ({ 
  clients, 
  selectedClient, 
  onClientSelect,
  industries,
  onClientDeleted 
}: ClientsListProps) => {
  const { toast } = useToast();
  const [editingClient, setEditingClient] = useState<Client | null>(null);
  const [clientToDelete, setClientToDelete] = useState<Client | null>(null);

  const handleDelete = async (client: Client) => {
    try {
      const { error } = await supabase
        .from('clients')
        .delete()
        .eq('id', client.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Client deleted successfully",
      });

      if (onClientDeleted) {
        onClientDeleted();
      }
    } catch (error: any) {
      console.error('Error deleting client:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to delete client",
        variant: "destructive",
      });
    }
  };

  const handleDeleteClick = (client: Client, e: React.MouseEvent) => {
    e.stopPropagation();
    setClientToDelete(client);
  };

  const handleEdit = (client: Client, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingClient(client);
  };

  const handleColorChange = async (client: Client, color: string) => {
    try {
      const { error } = await supabase
        .from('clients')
        .update({ color: color })
        .eq('id', client.id);

      if (error) throw error;

      if (onClientDeleted) {
        onClientDeleted();
      }
    } catch (error: any) {
      console.error('Error updating client color:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to update client color",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="w-full">
      {clients.map((client) => (
        <div
          key={client.id}
          onClick={() => onClientSelect(client)}
          className={`flex items-center justify-between group cursor-pointer hover:bg-gray-50/50 ${
            selectedClient?.id === client.id ? 'bg-gray-50/80' : ''
          }`}
        >
          <div className="flex items-center gap-0.5">
            <ColorPicker
              currentColor={client.color || 'bg-gray-400'}
              onColorSelect={(color) => handleColorChange(client, color)}
            />
            <span className="text-xs truncate">{client.company}</span>
          </div>
          
          <div className="flex items-center opacity-0 group-hover:opacity-100 transition-opacity">
            <Button
              variant="ghost"
              size="icon"
              className="h-4 w-4"
              onClick={(e) => handleEdit(client, e)}
            >
              <Pencil className="h-2.5 w-2.5 text-gray-500" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-4 w-4"
              onClick={(e) => handleDeleteClick(client, e)}
            >
              <Trash2 className="h-2.5 w-2.5 text-gray-500 hover:text-red-500" />
            </Button>
          </div>
        </div>
      ))}

      <Dialog open={!!editingClient} onOpenChange={() => setEditingClient(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Client</DialogTitle>
          </DialogHeader>
          {editingClient && (
            <ClientForm 
              initialData={editingClient}
              onSuccess={() => {
                setEditingClient(null);
                if (onClientDeleted) {
                  onClientDeleted();
                }
              }}
              onCancel={() => setEditingClient(null)}
            />
          )}
        </DialogContent>
      </Dialog>

      <DeleteConfirmDialog
        isOpen={!!clientToDelete}
        onOpenChange={(open) => !open && setClientToDelete(null)}
        onConfirm={() => {
          if (clientToDelete) {
            handleDelete(clientToDelete);
            setClientToDelete(null);
          }
        }}
      />
    </div>
  );
};