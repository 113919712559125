import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface IndustrySelectorProps {
  industry: string;
  industries: string[];
  onIndustryChange: (value: string) => void;
}

const IndustrySelector = ({ industry, industries, onIndustryChange }: IndustrySelectorProps) => {
  if (!industries.length) return null;

  return (
    <div className="space-y-2">
      <label className="text-sm font-medium">Industry</label>
      <Select value={industry} onValueChange={onIndustryChange}>
        <SelectTrigger>
          <SelectValue placeholder="Select an industry" />
        </SelectTrigger>
        <SelectContent>
          {industries.map((ind) => (
            <SelectItem key={ind} value={ind}>
              {ind}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default IndustrySelector;