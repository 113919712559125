import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import Header from "@/components/Header";
import { StripePricingTable } from "@/components/plans/StripePricingTable";

const Plans = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <StripePricingTable />
        </div>
      </div>
    </div>
  );
};

export default Plans;