import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { formatPhoneNumber } from "@/lib/formatPhone";

interface ProspectInfoProps {
  prospectId: number;
}

interface ProspectDetails {
  company: string;
  contact_name: string | null;
  phone: string | null;
  email: string | null;
  location: string | null;
}

const ProspectInfo = ({ prospectId }: ProspectInfoProps) => {
  const [prospect, setProspect] = useState<ProspectDetails | null>(null);

  useEffect(() => {
    const fetchProspect = async () => {
      const { data, error } = await supabase
        .from('prospects')
        .select('*')
        .eq('id', prospectId)
        .single();

      if (error) {
        console.error('Error fetching prospect:', error);
        return;
      }

      setProspect(data);
    };

    fetchProspect();
  }, [prospectId]);

  if (!prospect) return null;

  return (
    <div className="space-y-2">
      <h2 className="text-sm font-semibold mb-2">{prospect.company}</h2>
      <div className="space-y-2 text-xs">
        <div>
          <span className="text-gray-500 block leading-tight">Contact</span>
          <span className="text-gray-700 block leading-tight">{prospect.contact_name || 'N/A'}</span>
        </div>
        
        <div>
          <span className="text-gray-500 block leading-tight">Phone</span>
          {prospect.phone ? (
            <a 
              href={`tel:${prospect.phone}`}
              className="text-gray-600 hover:text-gray-800 hover:underline block leading-tight"
            >
              {formatPhoneNumber(prospect.phone)}
            </a>
          ) : (
            <span className="text-gray-700 block leading-tight">N/A</span>
          )}
        </div>

        <div>
          <span className="text-gray-500 block leading-tight">Email</span>
          {prospect.email ? (
            <a 
              href={`mailto:${prospect.email}`}
              className="text-gray-600 hover:text-gray-800 hover:underline block leading-tight"
            >
              {prospect.email}
            </a>
          ) : (
            <span className="text-gray-700 block leading-tight">N/A</span>
          )}
        </div>

        <div>
          <span className="text-gray-500 block leading-tight">Location</span>
          <span className="text-gray-700 block leading-tight">{prospect.location || 'N/A'}</span>
        </div>
      </div>
    </div>
  );
};

export default ProspectInfo;