import React from 'react';

interface RegistrationHeaderProps {
  registrationType: string;
}

export const RegistrationHeader: React.FC<RegistrationHeaderProps> = ({ registrationType }) => {
  return (
    <div className="text-center">
      <h1 className="text-2xl font-bold">Create an Account</h1>
      <p className="text-muted-foreground mt-2">
        {registrationType === 'trial' 
          ? "Sign up to start your free trial"
          : "Complete your account setup"}
      </p>
    </div>
  );
};