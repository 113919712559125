import { CardContent } from "@/components/ui/card";
import FollowUpForm from "./FollowUpForm";
import FollowUpTable from "./FollowUpTable";
import { FollowUp } from "@/types/followUp";

interface FollowUpContentProps {
  selectedClient: { id: number } | null;
  showForm: boolean;
  followUps: FollowUp[];
  sortConfig: { key: keyof FollowUp; direction: 'asc' | 'desc' } | null;
  onSave: (followUpData: Omit<FollowUp, 'id'>) => Promise<void>;
  onCancel: () => void;
  onSort: (key: keyof FollowUp) => void;
  onDelete: (id: string) => Promise<void>;
  onUpdatePriority: (id: string, priority: string) => Promise<void>;
  onUpdateFollowUp?: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
  isLoading?: boolean;
}

const FollowUpContent = ({
  selectedClient,
  showForm,
  followUps,
  sortConfig,
  onSave,
  onCancel,
  onSort,
  onDelete,
  onUpdatePriority,
  onUpdateFollowUp,
  isLoading = false
}: FollowUpContentProps) => {
  return (
    <CardContent className="p-3">
      {!selectedClient && (
        <div className="text-center text-gray-500 py-4">
          Select a client to view or add follow-ups
        </div>
      )}
      {selectedClient && showForm ? (
        <FollowUpForm
          onSave={onSave}
          onCancel={onCancel}
          editingFollowUp={null}
        />
      ) : (
        selectedClient && followUps.length > 0 ? (
          <FollowUpTable
            followUps={followUps}
            sortConfig={sortConfig}
            handleSort={onSort}
            handleDelete={onDelete}
            onUpdatePriority={onUpdatePriority}
            onUpdateFollowUp={onUpdateFollowUp}
          />
        ) : selectedClient && (
          <div className="text-center text-gray-500 py-4">
            No follow-ups yet. Click the + button to add one.
          </div>
        )
      )}
    </CardContent>
  );
};

export default FollowUpContent;