import { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import { FollowUp } from "@/types/followUp";
import { Client } from "@/types/client";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import FollowUpHeader from "../follow-up/FollowUpHeader";
import FollowUpContent from "../follow-up/FollowUpContent";
import { useFollowUps } from "@/hooks/useFollowUps";
import { followUpService } from "@/services/followUpService";

interface FollowUpPanelProps {
  selectedClient: Client | null;
}

const FollowUpPanel = ({ selectedClient }: FollowUpPanelProps) => {
  const [showForm, setShowForm] = useState(false);
  const { toast } = useToast();
  const { followUps, sortConfig, handleSort, fetchFollowUps } = useFollowUps(selectedClient);

  useEffect(() => {
    fetchFollowUps();
  }, [selectedClient]);

  const handleSave = async (followUpData: Omit<FollowUp, 'id'>) => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user?.id) throw new Error("No authenticated user");

      await followUpService.createFollowUp({
        ...followUpData,
        user_id: session.session.user.id,
        client_id: selectedClient?.id as number
      });

      await fetchFollowUps();
      setShowForm(false);
      
      toast({
        title: "Success",
        description: "Follow-up saved successfully",
      });
    } catch (error: any) {
      console.error('Error saving follow-up:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to save follow-up",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await followUpService.deleteFollowUp(id);
      await fetchFollowUps();
      
      toast({
        title: "Success",
        description: "Follow-up deleted successfully",
      });
    } catch (error: any) {
      console.error('Error deleting follow-up:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to delete follow-up",
        variant: "destructive",
      });
    }
  };

  const handleUpdatePriority = async (id: string, priority: FollowUp['priority']) => {
    try {
      await followUpService.updatePriority(id, priority);
      await fetchFollowUps();
      toast({
        title: "Success",
        description: "Priority updated successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to update priority",
        variant: "destructive",
      });
    }
  };

  const handleUpdateFollowUp = async (id: string, field: keyof FollowUp, value: string) => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user?.id) throw new Error("No authenticated user");

      if (field === 'status') {
        await followUpService.updateStatus(id, value as FollowUp['status']);
      } else if (field === 'action') {
        await followUpService.updateAction(id, value);
      } else {
        await supabase
          .from('follow_ups')
          .update({ [field]: value })
          .eq('id', id)
          .eq('user_id', session.session.user.id);
      }

      await fetchFollowUps();
      
      toast({
        title: "Success",
        description: "Follow-up updated successfully",
      });
    } catch (error: any) {
      console.error('Error updating follow-up:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to update follow-up",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="h-fit relative border shadow-sm w-full">
      <FollowUpHeader
        onAddClick={() => {
          setShowForm(true);
        }}
        disabled={!selectedClient}
      />
      <FollowUpContent
        selectedClient={selectedClient}
        showForm={showForm}
        followUps={followUps}
        sortConfig={sortConfig}
        onSave={handleSave}
        onCancel={() => {
          setShowForm(false);
        }}
        onSort={handleSort}
        onDelete={handleDelete}
        onUpdatePriority={handleUpdatePriority}
        onUpdateFollowUp={handleUpdateFollowUp}
      />
    </Card>
  );
};

export default FollowUpPanel;