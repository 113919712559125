import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";

interface Industry {
  id: number;
  name: string;
}

interface IndustrySelectorProps {
  industries: Industry[];
  selectedIndustries: string[];
  onIndustryChange: (industries: string[]) => void;
  label?: string;
}

const IndustrySelector = ({
  industries,
  selectedIndustries,
  onIndustryChange,
  label = "Industries"
}: IndustrySelectorProps) => {
  const handleIndustryToggle = (industryName: string) => {
    const updatedIndustries = selectedIndustries.includes(industryName)
      ? selectedIndustries.filter((name) => name !== industryName)
      : [...selectedIndustries, industryName];
    onIndustryChange(updatedIndustries);
  };

  return (
    <div className="space-y-2">
      <Label>{label}</Label>
      <ScrollArea className="h-[200px] w-full rounded-md border p-4">
        <div className="space-y-2">
          {industries.map((industry) => (
            <div key={industry.id} className="flex items-center space-x-2">
              <Checkbox
                id={`industry-${industry.id}`}
                checked={selectedIndustries.includes(industry.name)}
                onCheckedChange={() => handleIndustryToggle(industry.name)}
              />
              <label
                htmlFor={`industry-${industry.id}`}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {industry.name}
              </label>
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default IndustrySelector;