import { supabase } from "@/integrations/supabase/client";
import { FollowUp } from "@/types/followUp";

export const followUpService = {
  async updateFollowUp(followUpData: Partial<FollowUp>, followUpId: string, userId: string) {
    console.log('Attempting to update follow-up:', { followUpData, followUpId, userId });
    
    const { data, error } = await supabase
      .from('follow_ups')
      .update({
        ...followUpData,
        user_id: userId
      })
      .eq('id', followUpId)
      .select()
      .single();

    if (error) {
      console.error('Error updating follow-up:', error);
      throw error;
    }
    
    console.log('Follow-up updated successfully:', data);
    return data;
  },

  async createFollowUp(followUpData: Omit<FollowUp, 'id'>) {
    console.log('Attempting to create follow-up:', followUpData);
    
    const { error } = await supabase
      .from('follow_ups')
      .insert([followUpData]);

    if (error) {
      console.error('Error creating follow-up:', error);
      throw error;
    }
    
    console.log('Follow-up created successfully');
  },

  async deleteFollowUp(id: string) {
    console.log('Attempting to delete follow-up:', id);
    
    // First, delete any associated reminds
    const { error: remindsError } = await supabase
      .from('reminds')
      .delete()
      .eq('follow_up_id', id);

    if (remindsError) {
      console.error('Error deleting associated reminds:', remindsError);
      throw remindsError;
    }

    // Then delete the follow-up
    const { error } = await supabase
      .from('follow_ups')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting follow-up:', error);
      throw error;
    }
    
    console.log('Follow-up deleted successfully');
  },

  async updatePriority(id: string, priority: FollowUp['priority']) {
    console.log('Attempting to update follow-up priority:', { id, priority });
    
    const { error } = await supabase
      .from('follow_ups')
      .update({ priority })
      .eq('id', id);

    if (error) {
      console.error('Error updating follow-up priority:', error);
      throw error;
    }
    
    console.log('Follow-up priority updated successfully');
  },

  async updateStatus(id: string, status: FollowUp['status']) {
    console.log('Attempting to update follow-up status:', { id, status });
    
    const { error } = await supabase
      .from('follow_ups')
      .update({ status })
      .eq('id', id);

    if (error) {
      console.error('Error updating follow-up status:', error);
      throw error;
    }
    
    console.log('Follow-up status updated successfully');
  },

  async updateAction(id: string, action: string) {
    console.log('Attempting to update follow-up action:', { id, action });
    
    const { error } = await supabase
      .from('follow_ups')
      .update({ action })
      .eq('id', id);

    if (error) {
      console.error('Error updating follow-up action:', error);
      throw error;
    }
    
    console.log('Follow-up action updated successfully');
  }
};