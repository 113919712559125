import { ChevronDown, Pencil, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState } from "react";
import { useIsMobile } from "@/hooks/use-mobile";

interface Industry {
  id: number;
  name: string;
  color: string;
}

interface IndustrySelectProps {
  industries: Industry[];
  onIndustrySelect: (industry: string) => void;
  onEditClick: (id: number, name: string) => void;
  onDeleteClick: (id: number) => void;
  selectedIndustry?: string;
}

const IndustrySelect = ({
  industries,
  onIndustrySelect,
  onEditClick,
  onDeleteClick,
  selectedIndustry,
}: IndustrySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleSelect = (industry: string) => {
    onIndustrySelect(industry);
    setIsOpen(false);
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          className="w-full justify-between min-h-[40px] px-3"
        >
          <div className="flex items-center gap-1.5 truncate">
            {selectedIndustry ? (
              <>
                <div className="h-2.5 w-2.5 rounded-full bg-green-500 flex-shrink-0" />
                <span className="font-normal truncate">{selectedIndustry}</span>
              </>
            ) : (
              "Select Industry"
            )}
          </div>
          <ChevronDown className="ml-2 h-4 w-4 flex-shrink-0" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        className={`bg-white ${isMobile ? 'w-[calc(100vw-32px)]' : 'w-[300px]'} max-h-[300px] overflow-y-auto`}
        align="start"
        side="bottom"
        sideOffset={4}
        avoidCollisions={true}
      >
        {industries.map((industry) => (
          <DropdownMenuItem
            key={industry.id}
            className="group flex items-center justify-between py-2 px-2 cursor-pointer hover:bg-gray-50"
            onClick={() => handleSelect(industry.name)}
          >
            <div className="flex items-center gap-1.5 min-w-0 flex-1">
              <div className="h-2.5 w-2.5 rounded-full bg-gray-400 flex-shrink-0" />
              <span className="font-normal truncate">{industry.name}</span>
            </div>
            <div 
              className={`flex items-center gap-1 ${isMobile ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="h-6 w-6 p-0 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEditClick(industry.id, industry.name);
                  setIsOpen(false);
                }}
              >
                <Pencil className="h-3 w-3 text-gray-500 hover:text-blue-500" />
              </Button>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="h-6 w-6 p-0 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteClick(industry.id);
                  setIsOpen(false);
                }}
              >
                <Trash2 className="h-3 w-3 text-gray-500 hover:text-red-500" />
              </Button>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default IndustrySelect;