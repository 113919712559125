import { MessageSquarePlus, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";

interface FollowUpHeaderProps {
  onAddClick: () => void;
  disabled: boolean;
}

const FollowUpHeader = ({ onAddClick, disabled }: FollowUpHeaderProps) => {
  return (
    <CardHeader className="bg-gray-50/80 border-b py-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <MessageSquarePlus className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-medium text-gray-700">Follow Up</CardTitle>
        </div>
        <Button
          onClick={onAddClick}
          className="bg-gray-100 text-gray-700 hover:bg-gray-200"
          size="icon"
          disabled={disabled}
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>
    </CardHeader>
  );
};

export default FollowUpHeader;