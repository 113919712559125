import { FollowUp } from "@/types/followUp";
import { cn } from "@/lib/utils";
import { useIsMobile } from "@/hooks/use-mobile";
import { useState } from "react";
import FollowUpActions from "./table/FollowUpActions";
import FollowUpPriorityCell from "./table/FollowUpPriorityCell";
import FollowUpStatusCell from "./table/FollowUpStatusCell";
import FollowUpMobileDetails from "./table/FollowUpMobileDetails";
import FollowUpEditableCell from "./table/FollowUpEditableCell";
import FollowUpActionCell from "./table/FollowUpActionCell";
import { useToast } from "@/hooks/use-toast";

interface FollowUpTableProps {
  followUps: FollowUp[];
  sortConfig: { key: keyof FollowUp; direction: 'asc' | 'desc' } | null;
  handleSort: (key: keyof FollowUp) => void;
  handleDelete: (id: string) => void;
  onUpdatePriority: (id: string, priority: string) => Promise<void>;
  onUpdateFollowUp?: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
}

const FollowUpTable = ({ 
  followUps, 
  sortConfig, 
  handleSort, 
  handleDelete,
  onUpdatePriority,
  onUpdateFollowUp,
}: FollowUpTableProps) => {
  const isMobile = useIsMobile();
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const { toast } = useToast();
  
  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${year.slice(2)}-${month}-${day}`;
  };

  const toggleRow = (id: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleCellUpdate = async (id: string, field: keyof FollowUp, value: string) => {
    console.log('Attempting to update follow-up:', { id, field, value });
    if (onUpdateFollowUp) {
      try {
        await onUpdateFollowUp(id, field, value);
        console.log('Update successful');
        toast({
          title: "Success",
          description: "Follow-up updated successfully",
        });
      } catch (error) {
        console.error('Error updating follow-up:', error);
        toast({
          title: "Error",
          description: "Failed to update follow-up. Please try again.",
          variant: "destructive",
        });
      }
    } else {
      console.warn('onUpdateFollowUp callback is not provided');
    }
  };

  const onDelete = async (id: string) => {
    try {
      await handleDelete(id);
      toast({
        title: "Success",
        description: "Follow-up deleted successfully",
      });
    } catch (error) {
      console.error('Error deleting follow-up:', error);
      toast({
        title: "Error",
        description: "Failed to delete follow-up. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="overflow-x-auto -mx-3">
      <div className="md:hidden text-xs text-gray-500 italic px-4 py-2">
        Double-click on a cell to edit
      </div>
      <div className="md:px-0 px-4">
        <table className="w-full">
          <thead className={cn(isMobile && "sr-only")}>
            <tr className="border-b border-gray-200">
              <th className="text-left px-1 py-2 text-xs md:text-sm font-medium text-gray-600 w-[40px]" onClick={() => handleSort('priority')}>Priority</th>
              <th className="text-left px-1 py-2 text-xs md:text-sm font-medium text-gray-600 w-[70px]" onClick={() => handleSort('date')}>Date</th>
              <th className="text-left px-1 py-2 text-xs md:text-sm font-medium text-gray-600 w-[80px]" onClick={() => handleSort('action')}>Action</th>
              <th className="text-left -ml-4 px-0 py-2 text-xs md:text-sm font-medium text-gray-600 w-[60px]" onClick={() => handleSort('status')}>Status</th>
              {!isMobile && (
                <>
                  <th className="text-left px-1 py-2 text-xs md:text-sm font-medium text-gray-600" onClick={() => handleSort('description')}>Description</th>
                  <th className="text-left px-1 py-2 text-xs md:text-sm font-medium text-gray-600" onClick={() => handleSort('next_step')}>Next Step</th>
                </>
              )}
              <th className="w-8"></th>
            </tr>
          </thead>
          <tbody>
            {followUps.map((followUp) => (
              <>
                <tr 
                  key={followUp.id} 
                  className={cn(
                    "border-b border-gray-100 hover:bg-gray-50",
                    expandedRows[followUp.id] && "bg-gray-50"
                  )}
                >
                  <td className="px-1 py-2">
                    <FollowUpPriorityCell
                      priority={followUp.priority}
                      onUpdatePriority={(priority) => onUpdatePriority(followUp.id, priority)}
                    />
                  </td>
                  <td className="px-1 py-2 text-xs">
                    <FollowUpEditableCell
                      value={formatDate(followUp.date)}
                      onSave={(value) => handleCellUpdate(followUp.id, 'date', value)}
                    />
                  </td>
                  <td className="px-1 py-2 text-xs">
                    <FollowUpActionCell
                      action={followUp.action}
                      onUpdateAction={(action) => handleCellUpdate(followUp.id, 'action', action)}
                    />
                  </td>
                  <td className="px-0 -ml-4 py-2 text-xs">
                    <FollowUpStatusCell 
                      status={followUp.status}
                      onUpdateStatus={(status) => handleCellUpdate(followUp.id, 'status', status)}
                    />
                  </td>
                  {!isMobile && (
                    <>
                      <td className="px-1 py-2 text-xs">
                        <FollowUpEditableCell
                          value={followUp.description}
                          onSave={(value) => handleCellUpdate(followUp.id, 'description', value)}
                          type="textarea"
                        />
                      </td>
                      <td className="px-1 py-2 text-xs">
                        <FollowUpEditableCell
                          value={followUp.next_step}
                          onSave={(value) => handleCellUpdate(followUp.id, 'next_step', value)}
                          type="textarea"
                        />
                      </td>
                    </>
                  )}
                  <td className="p-1">
                    <FollowUpActions
                      isExpanded={expandedRows[followUp.id]}
                      isMobile={isMobile}
                      onDelete={() => onDelete(followUp.id)}
                      onToggle={() => toggleRow(followUp.id)}
                    />
                  </td>
                </tr>
                {isMobile && expandedRows[followUp.id] && (
                  <FollowUpMobileDetails 
                    followUp={followUp}
                    onUpdate={handleCellUpdate}
                  />
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FollowUpTable;