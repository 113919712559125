import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Plus, Save } from "lucide-react";
import ContactBasicInfo from "./form/ContactBasicInfo";
import ContactLocationInfo from "./form/ContactLocationInfo";
import ContactSelectors from "./form/ContactSelectors";
import { TradeShowContact } from "@/types/tradeShow";

interface TradeShowContactFormProps {
  contact?: TradeShowContact;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const TradeShowContactForm = ({ contact, onSuccess, onCancel }: TradeShowContactFormProps) => {
  const { toast } = useToast();
  const [industries, setIndustries] = useState<any[]>([]);
  const [tradeShows, setTradeShows] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    company: "",
    contact: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    country: "",
    selectedIndustries: [] as string[],
    tradeShowId: null as number | null,
  });

  useEffect(() => {
    if (contact) {
      setFormData({
        company: contact.company || "",
        contact: contact.contact || "",
        phone: contact.phone || "",
        email: contact.email || "",
        city: contact.city || "",
        state: contact.state || "",
        country: contact.country || "",
        selectedIndustries: contact.industries || [],
        tradeShowId: contact.trade_show_id || null,
      });
      fetchContactIndustries(contact.id);
    }
  }, [contact]);

  useEffect(() => {
    fetchIndustries();
    fetchTradeShows();
  }, []);

  const fetchContactIndustries = async (contactId: number) => {
    try {
      const { data, error } = await supabase
        .from("trade_show_contact_industries")
        .select("industry_name")
        .eq("contact_id", contactId);

      if (error) throw error;
      
      setFormData(prev => ({
        ...prev,
        selectedIndustries: data?.map(item => item.industry_name) || []
      }));
    } catch (error) {
      console.error("Error fetching contact industries:", error);
    }
  };

  const fetchIndustries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from("show_industries")
        .select("*")
        .eq("user_id", user.id);

      if (error) throw error;
      setIndustries(data || []);
    } catch (error) {
      console.error("Error fetching industries:", error);
    }
  };

  const fetchTradeShows = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from("trade_shows")
        .select("*")
        .eq("user_id", user.id);

      if (error) throw error;
      setTradeShows(data || []);
    } catch (error) {
      console.error("Error fetching trade shows:", error);
    }
  };

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      const contactData = {
        company: formData.company,
        contact: formData.contact,
        phone: formData.phone,
        email: formData.email,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        trade_show_id: formData.tradeShowId,
        user_id: user.id,
      };

      let contactId;
      if (contact) {
        // Update existing contact
        const { error } = await supabase
          .from("trade_show_contacts")
          .update(contactData)
          .eq("id", contact.id);

        if (error) throw error;
        contactId = contact.id;
      } else {
        // Insert new contact
        const { data, error } = await supabase
          .from("trade_show_contacts")
          .insert(contactData)
          .select()
          .single();

        if (error) throw error;
        contactId = data.id;
      }

      // Delete existing industries for this contact
      await supabase
        .from("trade_show_contact_industries")
        .delete()
        .eq("contact_id", contactId);

      // Insert new industries
      if (formData.selectedIndustries.length > 0) {
        const industriesData = formData.selectedIndustries.map(industry => ({
          contact_id: contactId,
          industry_name: industry,
          user_id: user.id
        }));

        const { error: industriesError } = await supabase
          .from("trade_show_contact_industries")
          .insert(industriesData);

        if (industriesError) throw industriesError;
      }

      toast({
        title: "Success",
        description: contact ? "Contact updated successfully" : "Contact added successfully",
      });
      
      if (onSuccess) onSuccess();
    } catch (error: any) {
      console.error("Error saving contact:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to save contact",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <ContactBasicInfo formData={formData} onChange={handleChange} />
        <ContactLocationInfo formData={formData} onChange={handleChange} />
        <ContactSelectors 
          formData={formData} 
          industries={industries} 
          tradeShows={tradeShows} 
          onChange={handleChange} 
        />
      </div>

      <div className="flex justify-end gap-2 pt-4 border-t">
        {onCancel && (
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit">
          {contact ? (
            <>
              <Save className="w-4 h-4 mr-2" />
              Update Contact
            </>
          ) : (
            <>
              <Plus className="w-4 h-4 mr-2" />
              Add Contact
            </>
          )}
        </Button>
      </div>
    </form>
  );
};

export default TradeShowContactForm;