import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import ProspectForm from "./ProspectForm";
import { Users, Plus, Pencil, Trash2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface ProspectsListProps {
  onSelectProspect: (id: number) => void;
  selectedIndustry: string | null;
  industries: string[];
  onIndustryAdded: () => void;
}

const ProspectsList = ({ 
  onSelectProspect, 
  selectedIndustry,
  industries,
  onIndustryAdded
}: ProspectsListProps) => {
  const [prospects, setProspects] = useState<any[]>([]);
  const [isAddingProspect, setIsAddingProspect] = useState(false);
  const [editingProspect, setEditingProspect] = useState<any>(null);
  const { toast } = useToast();

  const fetchProspects = async () => {
    try {
      console.log('Fetching prospects...');
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.log('No user found');
        return;
      }

      let query = supabase
        .from('prospects')
        .select('*')
        .eq('user_id', user.id);

      if (selectedIndustry) {
        query = query.eq('industry', selectedIndustry);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching prospects:', error);
        throw error;
      }
      
      console.log('Fetched prospects:', data);
      const sortedProspects = (data || []).sort((a, b) => 
        a.company.localeCompare(b.company)
      );
      
      setProspects(sortedProspects);
    } catch (error) {
      console.error('Error fetching prospects:', error);
      toast({
        title: "Error",
        description: "Failed to load prospects",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchProspects();
  }, [selectedIndustry]);

  const handleProspectAdded = () => {
    setIsAddingProspect(false);
    setEditingProspect(null);
    fetchProspects();
    onIndustryAdded();
  };

  const handleDeleteProspect = async (id: number) => {
    try {
      const { error } = await supabase
        .from('prospects')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Prospect deleted successfully",
      });
      fetchProspects();
    } catch (error) {
      console.error('Error deleting prospect:', error);
      toast({
        title: "Error",
        description: "Failed to delete prospect",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between bg-gray-50/80 border-b p-3">
        <div className="flex items-center gap-2">
          <Users className="h-5 w-5 text-gray-500" />
          <h2 className="text-lg font-semibold">Prospects</h2>
        </div>
        <Dialog open={isAddingProspect} onOpenChange={setIsAddingProspect}>
          <DialogTrigger asChild>
            <Button 
              variant="ghost" 
              size="sm"
              className="h-8 w-8"
            >
              <Plus className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Prospect</DialogTitle>
            </DialogHeader>
            <ProspectForm 
              onSuccess={() => {
                setIsAddingProspect(false);
                fetchProspects();
                onIndustryAdded();
              }} 
              selectedIndustry={selectedIndustry}
              industries={industries}
            />
          </DialogContent>
        </Dialog>
      </div>
      <div className="p-2">
        <div className="space-y-0.5">
          {prospects.map((prospect) => (
            <div
              key={prospect.id}
              className="flex items-center justify-between group"
            >
              <Button
                variant="ghost"
                className="w-full justify-start px-2 py-1 h-8 text-sm font-normal hover:bg-gray-50"
                onClick={() => onSelectProspect(prospect.id)}
              >
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-300"></div>
                  <div className="text-left">
                    <div className="text-sm">{prospect.company}</div>
                    {prospect.contact_name && (
                      <div className="text-xs text-gray-500">{prospect.contact_name}</div>
                    )}
                  </div>
                </div>
              </Button>
              <div className="flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-7 w-7"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingProspect(prospect);
                      }}
                    >
                      <Pencil className="h-3 w-3" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Edit Prospect</DialogTitle>
                    </DialogHeader>
                    <ProspectForm 
                      onSuccess={() => {
                        setEditingProspect(null);
                        fetchProspects();
                        onIndustryAdded();
                      }}
                      selectedIndustry={selectedIndustry}
                      industries={industries}
                      initialData={prospect}
                    />
                  </DialogContent>
                </Dialog>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-7 w-7"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Trash2 className="h-3 w-3" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the prospect.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => handleDeleteProspect(prospect.id)}>
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProspectsList;