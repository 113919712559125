import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import Header from "@/components/Header";
import LoginForm from "@/components/auth/LoginForm";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm";

const Login = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    try {
      const { data: { session }, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      if (session) {
        // Special case for admin user
        if (session.user.email === 'jstaack@staackllc.com') {
          console.log('Admin user detected, redirecting to dashboard');
          navigate("/dashboard");
          return;
        }

        console.log('Login successful, redirecting to dashboard');
        toast({
          title: "Login successful!",
          description: "Welcome back",
        });
        navigate("/dashboard");
      }
    } catch (error: any) {
      console.error("Login error:", error);
      toast({
        title: "Error",
        description: error.message || "Invalid email or password. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (email: string) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;

      setResetEmailSent(true);
      toast({
        title: "Success",
        description: "Check your email for the password reset link",
      });
    } catch (error: any) {
      console.error("Password reset error:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to send reset email. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="flex-1 flex items-center justify-center px-4 py-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold">
              {showResetPassword ? "Reset Password" : "Welcome Back"}
            </h1>
            <p className="text-muted-foreground mt-2">
              {showResetPassword
                ? "Enter your email to receive a reset link"
                : "Sign in to your account"}
            </p>
          </div>

          {showResetPassword ? (
            <ResetPasswordForm
              onSubmit={handleForgotPassword}
              onBack={() => setShowResetPassword(false)}
              loading={loading}
              resetEmailSent={resetEmailSent}
            />
          ) : (
            <>
              <LoginForm onSubmit={handleLogin} loading={loading} />
              <button
                type="button"
                onClick={() => setShowResetPassword(true)}
                className="w-full text-sm text-muted-foreground hover:text-foreground transition-colors"
              >
                Forgot your password?
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;