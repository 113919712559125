import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { actionOptions } from "@/utils/followUpUtils";

interface FollowUpActionCellProps {
  action: string;
  onUpdateAction?: (action: string) => void;
}

const FollowUpActionCell = ({ action, onUpdateAction }: FollowUpActionCellProps) => {
  if (!onUpdateAction) {
    return <div className="capitalize">{action}</div>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="capitalize cursor-pointer hover:bg-gray-50 p-1 rounded">
          {action}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {actionOptions.map((option) => (
          <DropdownMenuItem
            key={option}
            onClick={() => onUpdateAction(option)}
          >
            <span className="capitalize">{option}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default FollowUpActionCell;