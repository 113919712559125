import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import Header from "@/components/Header";
import FollowUpPanel from "@/components/panels/FollowUpPanel";
import ClientsListPanel from "@/components/panels/ClientsListPanel";
import ClientInfoPanel from "@/components/panels/ClientInfoPanel";
import ActivitiesPanel from "@/components/panels/ActivitiesPanel";
import WeeklyCalendarPanel from "@/components/panels/WeeklyCalendarPanel";
import IndustriesPanel from "@/components/panels/IndustriesPanel";
import RemindsPanel from "@/components/panels/RemindsPanel";
import { Client } from "@/types/client";

const Index = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");

  const handleClientSelect = (client: Client | null) => {
    setSelectedClient(client);
  };

  const handleClientSelectFromRemind = async (clientId: number) => {
    try {
      const { data: client } = await supabase
        .from('clients')
        .select('*')
        .eq('id', clientId)
        .single();

      if (client) {
        setSelectedClient(client);
        setSelectedIndustry(client.industry);
      }
    } catch (error) {
      console.error('Error fetching client:', error);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Dashboard</h1>
        
        {/* Mobile Layout (hidden on md and up) */}
        <div className="md:hidden space-y-4">
          <IndustriesPanel 
            onIndustrySelect={setSelectedIndustry} 
            selectedIndustry={selectedIndustry}
          />
          <ClientsListPanel
            selectedClient={selectedClient}
            onClientSelect={handleClientSelect}
            selectedIndustry={selectedIndustry}
          />
          <ClientInfoPanel selectedClient={selectedClient} />
          <FollowUpPanel selectedClient={selectedClient} />
          <WeeklyCalendarPanel />
          <RemindsPanel 
            selectedIndustry={selectedIndustry} 
            onClientSelect={handleClientSelectFromRemind}
          />
          <ActivitiesPanel />
        </div>

        {/* Desktop Layout (hidden on mobile, visible from md up) */}
        <div className="hidden md:grid md:grid-cols-12 md:gap-4">
          <div className="md:col-span-3 space-y-4">
            <IndustriesPanel 
              onIndustrySelect={setSelectedIndustry}
              selectedIndustry={selectedIndustry}
            />
            <ClientsListPanel
              selectedClient={selectedClient}
              onClientSelect={handleClientSelect}
              selectedIndustry={selectedIndustry}
            />
            <ClientInfoPanel selectedClient={selectedClient} />
          </div>
          <div className="md:col-span-6">
            <div className="space-y-4">
              <WeeklyCalendarPanel />
              <FollowUpPanel selectedClient={selectedClient} />
            </div>
          </div>
          <div className="md:col-span-3">
            <div className="space-y-4">
              <RemindsPanel 
                selectedIndustry={selectedIndustry} 
                onClientSelect={handleClientSelectFromRemind}
              />
              <ActivitiesPanel />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;