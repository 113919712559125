import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { X } from "lucide-react";
import { BasicInfoFields } from "./form/BasicInfoFields";
import { ContactFields } from "./form/ContactFields";
import { AddressFields } from "./form/AddressFields";
import { CompanyContactsFields } from "./form/CompanyContactsFields";
import { Client } from "@/types/client";

interface ClientFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  initialData?: Client;
}

interface Contact {
  name: string;
  phone: string;
  mobile: string;
  email: string;
}

const ClientForm = ({ onSuccess, onCancel, initialData }: ClientFormProps) => {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    company: initialData?.company || "",
    contact: initialData?.contact || "",
    phone: initialData?.phone || "",
    mobile: initialData?.mobile || "",
    email: initialData?.email || "",
    web: initialData?.web || "",
    address: initialData?.address || "",
    city: initialData?.city || "",
    state: initialData?.state || "",
    zip: initialData?.zip || "",
    notes: initialData?.notes || "",
    industry: initialData?.industry || "",
  });

  const [additionalContacts, setAdditionalContacts] = useState<Contact[]>([]);

  // Fetch existing additional contacts when editing
  useEffect(() => {
    const fetchAdditionalContacts = async () => {
      if (!initialData?.id) return;

      try {
        const { data, error } = await supabase
          .from('company_contacts')
          .select('*')
          .eq('client_id', initialData.id);

        if (error) throw error;

        if (data) {
          const contacts = data.map(contact => ({
            name: contact.name || '',
            phone: contact.phone || '',
            mobile: contact.mobile || '',
            email: contact.email || ''
          }));
          setAdditionalContacts(contacts);
        }
      } catch (error: any) {
        console.error('Error fetching additional contacts:', error);
        toast({
          title: "Error",
          description: "Failed to load additional contacts",
          variant: "destructive",
        });
      }
    };

    fetchAdditionalContacts();
  }, [initialData?.id]);

  const handleChange = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.industry) {
      toast({
        title: "Error",
        description: "Please select an industry",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      let clientId: number;

      if (initialData) {
        // Update existing client
        const { error } = await supabase
          .from("clients")
          .update(formData)
          .eq('id', initialData.id);

        if (error) throw error;
        clientId = initialData.id;

        // Delete existing additional contacts
        const { error: deleteError } = await supabase
          .from("company_contacts")
          .delete()
          .eq('client_id', initialData.id);

        if (deleteError) throw deleteError;

        toast({
          title: "Success",
          description: "Client updated successfully",
        });
      } else {
        // Add new client
        const { data: clientData, error } = await supabase
          .from("clients")
          .insert({
            ...formData,
            user_id: user.id,
            created_at: new Date().toISOString(),
          })
          .select()
          .single();

        if (error) throw error;
        if (!clientData) throw new Error("Failed to create client");
        
        clientId = clientData.id;

        toast({
          title: "Success",
          description: "Client added successfully",
        });
      }

      // Handle additional contacts
      if (additionalContacts.length > 0) {
        const { error: contactsError } = await supabase
          .from("company_contacts")
          .insert(
            additionalContacts.map(contact => ({
              client_id: clientId,
              user_id: user.id,
              ...contact
            }))
          );

        if (contactsError) throw contactsError;
      }
      
      onSuccess();
    } catch (error: any) {
      console.error("Error saving client:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to save client",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-2">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-base font-medium">
          {initialData ? 'Edit Client' : 'Add New Client'}
        </h3>
        <Button 
          type="button" 
          variant="ghost" 
          size="icon"
          onClick={onCancel}
          className="h-6 w-6"
        >
          <X className="h-3 w-3" />
        </Button>
      </div>

      <div className="max-h-[500px] overflow-y-auto pr-2">
        <div className="grid grid-cols-2 gap-2">
          <BasicInfoFields formData={formData} onChange={handleChange} />
          <ContactFields formData={formData} onChange={handleChange} />
          <AddressFields formData={formData} onChange={handleChange} />

          <div className="col-span-2">
            <Label htmlFor="notes" className="text-sm">Notes</Label>
            <Textarea
              id="notes"
              value={formData.notes}
              onChange={(e) => handleChange("notes", e.target.value)}
              className="h-16 min-h-[4rem] text-sm"
            />
          </div>

          <div className="col-span-2">
            <CompanyContactsFields
              contacts={additionalContacts}
              onChange={setAdditionalContacts}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-end pt-2 border-t mt-2">
        <Button type="button" variant="outline" onClick={onCancel} className="h-8 text-sm">
          Cancel
        </Button>
        <Button type="submit" className="h-8 text-sm">
          {initialData ? 'Save Changes' : 'Add Client'}
        </Button>
      </div>
    </form>
  );
};

export default ClientForm;