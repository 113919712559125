import { Button } from "@/components/ui/button";
import { Edit2, Trash2 } from "lucide-react";
import { TradeShowContact } from "@/types/tradeShow";

interface ContactListItemProps {
  contact: TradeShowContact;
  onEdit: (contact: TradeShowContact) => void;
  onDelete: (contactId: number) => void;
  onClick: () => void;
}

const ContactListItem = ({ contact, onEdit, onDelete, onClick }: ContactListItemProps) => {
  return (
    <div 
      className="py-1.5 group hover:bg-gray-50 relative"
    >
      <div 
        className="cursor-pointer px-2"
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="font-medium text-sm">{contact.company}</div>
            {contact.contact && (
              <div className="text-xs text-gray-500">{contact.contact}</div>
            )}
          </div>
          <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(contact);
              }}
            >
              <Edit2 className="h-3 w-3" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(contact.id);
              }}
            >
              <Trash2 className="h-3 w-3 text-gray-400" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactListItem;