import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import Header from "@/components/Header";
import { RegistrationProvider } from "@/components/auth/RegistrationContext";
import { RegistrationHeader } from "@/components/auth/RegistrationHeader";
import { RegistrationForm } from "@/components/auth/RegistrationForm";
import { supabase } from "@/integrations/supabase/client";

const SignUp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const setupToken = searchParams.get('setup_token');
  const subscriptionType = searchParams.get('type');

  useEffect(() => {
    const verifySetupToken = async () => {
      if (!setupToken || !subscriptionType) {
        toast({
          title: "Invalid Access",
          description: "Please select a plan first to continue with registration.",
          variant: "destructive",
        });
        navigate('/plans');
        return;
      }

      // Verify the setup token exists in pending_subscriptions
      const { data: subscription, error } = await supabase
        .from('pending_subscriptions')
        .select('subscription_status, email')
        .eq('setup_token', setupToken)
        .single();

      if (error || !subscription || subscription.subscription_status !== 'active') {
        toast({
          title: "Invalid Setup Token",
          description: "This registration link is invalid or has expired.",
          variant: "destructive",
        });
        navigate('/plans');
        return;
      }

      console.log('Setup token verified:', setupToken);
    };

    verifySetupToken();
  }, [setupToken, subscriptionType, navigate, toast]);

  // Don't render anything until we verify the parameters
  if (!setupToken || !subscriptionType) {
    return null;
  }

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-md mx-auto space-y-8 animate-fade-up">
          <RegistrationProvider>
            <RegistrationHeader registrationType={subscriptionType} />
            <RegistrationForm />
          </RegistrationProvider>
        </div>
      </div>
    </div>
  );
};

export default SignUp;