import { Checkbox } from "@/components/ui/checkbox";
import { Trash2 } from "lucide-react";

interface TodoItemProps {
  id: number;
  text: string;
  completed: boolean;
  onToggle: (id: number, completed: boolean) => void;
  onDelete: (id: number) => void;
}

const TodoItem = ({ id, text, completed, onToggle, onDelete }: TodoItemProps) => {
  return (
    <div className="flex items-center justify-between group">
      <div className="flex items-center space-x-2">
        <Checkbox 
          id={`todo-${id}`} 
          checked={completed}
          onCheckedChange={() => onToggle(id, completed)}
          className="border-gray-400"
        />
        <label
          htmlFor={`todo-${id}`}
          className={`text-xs text-gray-600 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
            completed ? 'line-through' : ''
          }`}
        >
          {text}
        </label>
      </div>
      <button 
        onClick={() => onDelete(id)}
        className="opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <Trash2 className="h-4 w-4 text-gray-400 hover:text-red-500" />
      </button>
    </div>
  );
};

export default TodoItem;