import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { TradeShow } from "@/types/tradeShow";
import TradeShowCard from "./TradeShowCard";

interface TradeShowsListProps {
  selectedIndustry: string | null;
  refreshTrigger: number;
}

const TradeShowsList = ({ selectedIndustry, refreshTrigger }: TradeShowsListProps) => {
  const { toast } = useToast();
  const [tradeShows, setTradeShows] = useState<TradeShow[]>([]);

  const fetchTradeShows = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to view trade shows",
          variant: "destructive",
        });
        return;
      }

      let query = supabase
        .from('trade_shows')
        .select(`
          *,
          trade_show_industries!inner (
            industry_name
          )
        `)
        .eq('user_id', session.user.id);

      // Only apply industry filter if an industry is selected
      if (selectedIndustry) {
        query = query.eq('trade_show_industries.industry_name', selectedIndustry);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching trade shows:', error);
        throw error;
      }

      if (data) {
        const processedShows = data.map(show => ({
          ...show,
          type: show.type as 'exhibiting' | 'visiting',
          industry: show.trade_show_industries?.[0]?.industry_name || show.industry
        }));
        console.log('Processed trade shows:', processedShows);
        setTradeShows(processedShows);
      }
    } catch (error: any) {
      console.error('Error fetching trade shows:', error);
      toast({
        title: "Error",
        description: "Failed to fetch trade shows",
        variant: "destructive",
      });
    }
  };

  const handleDelete = (id: number) => {
    setTradeShows(current => current.filter(show => show.id !== id));
  };

  const handleTypeChange = (id: number, newType: 'exhibiting' | 'visiting') => {
    setTradeShows(current =>
      current.map(show =>
        show.id === id ? { ...show, type: newType } : show
      )
    );
  };

  useEffect(() => {
    fetchTradeShows();
  }, [selectedIndustry, refreshTrigger]);

  if (tradeShows.length === 0) {
    return (
      <div className="p-4 text-center text-gray-500">
        No trade shows found{selectedIndustry ? ` for ${selectedIndustry}` : ''}.
      </div>
    );
  }

  return (
    <div className="space-y-2 p-4">
      {tradeShows.map((show) => (
        <TradeShowCard
          key={show.id}
          show={show}
          onDelete={handleDelete}
          onTypeChange={handleTypeChange}
        />
      ))}
    </div>
  );
};

export default TradeShowsList;