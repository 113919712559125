import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { format } from "date-fns";

interface ContactNoteProps {
  id: number;
  noteText: string;
  createdAt: string;
  onDelete: (noteId: number) => void;
}

const ContactNote = ({ id, noteText, createdAt, onDelete }: ContactNoteProps) => {
  return (
    <div className="bg-white/40 rounded-lg p-2 shadow-sm">
      <div className="flex justify-between items-start gap-2">
        <div className="flex-grow">
          <span className="text-xs text-gray-500 block mb-1">
            {format(new Date(createdAt), "MMM d, yyyy h:mm a")}
          </span>
          <p className="text-sm text-gray-700 whitespace-pre-wrap">{noteText}</p>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="h-6 w-6"
          onClick={() => onDelete(id)}
        >
          <Trash2 className="h-4 w-4 text-gray-500 hover:text-red-500" />
        </Button>
      </div>
    </div>
  );
};

export default ContactNote;