import { cn } from "@/lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface FollowUpPriorityCellProps {
  priority: string;
  onUpdatePriority: (priority: string) => void;
}

const priorityColors = {
  high: "bg-red-500",
  medium: "bg-yellow-500",
  low: "bg-green-500",
  none: "bg-gray-400"
};

const priorityOptions = ["high", "medium", "low", "none"];

const FollowUpPriorityCell = ({ priority, onUpdatePriority }: FollowUpPriorityCellProps) => {
  return (
    <div className="flex justify-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className={cn(
              "w-2 md:w-3 h-2 md:h-3 rounded-full cursor-pointer",
              priorityColors[priority as keyof typeof priorityColors]
            )}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {priorityOptions.map((p) => (
            <DropdownMenuItem
              key={p}
              onClick={() => onUpdatePriority(p)}
            >
              <div className="flex items-center gap-2">
                <div
                  className={cn(
                    "w-2 h-2 rounded-full",
                    priorityColors[p as keyof typeof priorityColors]
                  )}
                />
                <span className="capitalize text-xs">{p}</span>
              </div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default FollowUpPriorityCell;