import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface FollowUpStatusCellProps {
  status: string;
  onUpdateStatus?: (status: string) => void;
}

const statusOptions = ["pending", "completed", "on hold", "canceled", "other"];

const FollowUpStatusCell = ({ status, onUpdateStatus }: FollowUpStatusCellProps) => {
  if (!onUpdateStatus) {
    return <div className="capitalize">{status}</div>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="capitalize cursor-pointer hover:bg-gray-50 p-1 rounded">
          {status}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {statusOptions.map((option) => (
          <DropdownMenuItem
            key={option}
            onClick={() => onUpdateStatus(option)}
          >
            <span className="capitalize">{option}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default FollowUpStatusCell;