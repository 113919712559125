import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface CategorySelectorProps {
  category: string;
  onCategoryChange: (value: string) => void;
}

const CategorySelector = ({ category, onCategoryChange }: CategorySelectorProps) => {
  return (
    <div className="space-y-2">
      <label className="text-sm font-medium">Category</label>
      <Select value={category} onValueChange={onCategoryChange}>
        <SelectTrigger>
          <SelectValue placeholder="Select a category" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="prospect">Prospect</SelectItem>
          <SelectItem value="trade_show">Trade Show</SelectItem>
          <SelectItem value="dashboard">Dashboard</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default CategorySelector;