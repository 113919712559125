interface PaginationControlsProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PaginationControls = ({ currentPage, totalPages, onPageChange }: PaginationControlsProps) => {
  return (
    <div className="flex justify-between items-center mt-4 px-2">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="text-xs px-2 py-1 disabled:opacity-50 hover:bg-gray-100 rounded"
      >
        Previous
      </button>
      <span className="text-xs text-gray-600">
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="text-xs px-2 py-1 disabled:opacity-50 hover:bg-gray-100 rounded"
      >
        Next
      </button>
    </div>
  );
};

export default PaginationControls;