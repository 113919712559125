import { useState, useEffect } from "react";
import Header from "@/components/Header";
import ProspectsList from "@/components/prospects/ProspectsList";
import ProspectInfo from "@/components/prospects/ProspectInfo";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import IndustryPanel from "@/components/prospects/panels/IndustryPanel";
import ProspectDetailsPanel from "@/components/prospects/panels/ProspectDetailsPanel";
import { User } from "lucide-react";

const Prospecting = () => {
  const [selectedProspect, setSelectedProspect] = useState<number | null>(null);
  const [industries, setIndustries] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);
  const { toast } = useToast();

  const fetchIndustries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('prospects')
        .select('industry')
        .eq('user_id', user.id)
        .not('industry', 'is', null);

      if (error) throw error;

      const uniqueIndustries = Array.from(new Set(data.map(item => item.industry))).filter(Boolean);
      setIndustries(uniqueIndustries);
    } catch (error) {
      console.error('Error fetching industries:', error);
      toast({
        title: "Error",
        description: "Failed to load industries",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchIndustries();
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Prospecting</h1>

        {/* Mobile Layout (hidden on md and up) */}
        <div className="md:hidden space-y-4">
          <div className="bg-white rounded-lg border shadow-sm">
            <IndustryPanel
              industries={industries}
              selectedIndustry={selectedIndustry}
              onIndustrySelect={setSelectedIndustry}
              onIndustryAdded={fetchIndustries}
            />
          </div>

          <div className="bg-white rounded-lg border shadow-sm">
            <ProspectsList 
              onSelectProspect={setSelectedProspect}
              selectedIndustry={selectedIndustry}
              industries={industries}
              onIndustryAdded={fetchIndustries}
            />
          </div>

          {selectedProspect && (
            <div className="bg-white rounded-lg border shadow-sm">
              <div className="bg-gray-50/80 border-b p-3">
                <div className="flex items-center gap-2">
                  <User className="h-5 w-5 text-gray-500" />
                  <h2 className="text-lg font-semibold">Prospect Info</h2>
                </div>
              </div>
              <div className="p-4">
                <ProspectInfo prospectId={selectedProspect} />
              </div>
            </div>
          )}

          <div className="bg-white rounded-lg border shadow-sm">
            <ProspectDetailsPanel selectedProspect={selectedProspect} />
          </div>
        </div>

        {/* Desktop Layout (hidden on mobile, visible from md up) */}
        <div className="hidden md:grid md:grid-cols-12 md:gap-4">
          <div className="md:col-span-3 space-y-4">
            <div className="bg-white rounded-lg border shadow-sm">
              <IndustryPanel
                industries={industries}
                selectedIndustry={selectedIndustry}
                onIndustrySelect={setSelectedIndustry}
                onIndustryAdded={fetchIndustries}
              />
            </div>

            {selectedProspect && (
              <div className="bg-white rounded-lg border shadow-sm">
                <div className="bg-gray-50/80 border-b p-3">
                  <div className="flex items-center gap-2">
                    <User className="h-5 w-5 text-gray-500" />
                    <h2 className="text-lg font-semibold">Prospect Info</h2>
                  </div>
                </div>
                <div className="p-4">
                  <ProspectInfo prospectId={selectedProspect} />
                </div>
              </div>
            )}

            <div className="bg-white rounded-lg border shadow-sm">
              <ProspectsList 
                onSelectProspect={setSelectedProspect}
                selectedIndustry={selectedIndustry}
                industries={industries}
                onIndustryAdded={fetchIndustries}
              />
            </div>
          </div>

          <div className="md:col-span-9">
            <div className="bg-white rounded-lg border shadow-sm">
              <ProspectDetailsPanel selectedProspect={selectedProspect} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prospecting;