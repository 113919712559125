import { useState, useCallback, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import TradeShowBasicInfo from "./form/TradeShowBasicInfo";
import IndustrySelector from "./form/IndustrySelector";

interface AddTradeShowFormProps {
  type: 'exhibiting' | 'visiting';
  selectedIndustry: string | null;
  onClose: () => void;
  onSuccess: () => void;
}

const AddTradeShowForm = ({ type, selectedIndustry, onClose, onSuccess }: AddTradeShowFormProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [industries, setIndustries] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    location: '',
    selectedIndustries: selectedIndustry ? [selectedIndustry] : [],
  });

  const fetchIndustries = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to view industries",
          variant: "destructive",
        });
        return;
      }

      const { data, error } = await supabase
        .from('show_industries')
        .select('*')
        .eq('user_id', session.user.id)
        .order('name');

      if (error) throw error;

      if (data) {
        setIndustries(data);
      }
    } catch (error: any) {
      console.error('Error fetching industries:', error);
      toast({
        title: "Error",
        description: "Failed to fetch industries",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchIndustries();

    const channel = supabase
      .channel('show-industries-changes-form')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'show_industries'
        },
        () => {
          fetchIndustries();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const validateForm = useCallback(() => {
    const errors = [];
    if (!formData.name.trim()) errors.push("Show name is required");
    if (!formData.startDate) errors.push("Start date is required");
    if (!formData.endDate) errors.push("End date is required");
    if (!formData.location.trim()) errors.push("Location is required");
    if (formData.selectedIndustries.length === 0) errors.push("At least one industry is required");
    
    if (formData.startDate && formData.endDate && formData.startDate > formData.endDate) {
      errors.push("Start date must be before end date");
    }
    
    return errors;
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      toast({
        title: "Validation Error",
        description: validationErrors.join(", "),
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to add a trade show",
          variant: "destructive",
        });
        return;
      }

      const dateRange = `${formData.startDate} to ${formData.endDate}`;
      
      // Insert trade show
      const { data: tradeShowData, error: tradeShowError } = await supabase
        .from('trade_shows')
        .insert([{
          name: formData.name.trim(),
          date: dateRange,
          city: formData.location.trim(),
          type,
          user_id: session.user.id,
        }])
        .select()
        .single();

      if (tradeShowError) throw tradeShowError;

      // Insert trade show industries
      const industriesData = formData.selectedIndustries.map(industry => ({
        trade_show_id: tradeShowData.id,
        industry_name: industry,
        user_id: session.user.id,
      }));

      const { error: industriesError } = await supabase
        .from('trade_show_industries')
        .insert(industriesData);

      if (industriesError) throw industriesError;

      toast({
        title: "Success",
        description: "Trade show added successfully",
      });
      
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error adding trade show:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to add trade show",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <TradeShowBasicInfo
        name={formData.name}
        startDate={formData.startDate}
        endDate={formData.endDate}
        location={formData.location}
        onNameChange={(value) => setFormData(prev => ({ ...prev, name: value }))}
        onStartDateChange={(value) => setFormData(prev => ({ ...prev, startDate: value }))}
        onEndDateChange={(value) => setFormData(prev => ({ ...prev, endDate: value }))}
        onLocationChange={(value) => setFormData(prev => ({ ...prev, location: value }))}
      />

      <IndustrySelector
        industries={industries}
        selectedIndustries={formData.selectedIndustries}
        onIndustryChange={(industries) => setFormData(prev => ({ ...prev, selectedIndustries: industries }))}
      />
      
      <div className="flex justify-end gap-2">
        <Button
          type="button"
          variant="outline"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Adding..." : "Add Show"}
        </Button>
      </div>
    </form>
  );
};

export default AddTradeShowForm;