import Header from "@/components/Header";
import ReportForm from "@/components/reports/ReportForm";

const Reports = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Reports</h1>
        <div className="max-w-2xl mx-auto">
          <ReportForm />
        </div>
      </div>
    </div>
  );
};

export default Reports;