import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { fetchIndustriesForCategory, fetchCompaniesForIndustry, fetchTradeShows, fetchFollowUps } from "../utils/reportQueries";

export const useReportData = () => {
  const { toast } = useToast();
  const [category, setCategory] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [industries, setIndustries] = useState<string[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [notes, setNotes] = useState<string>("");
  const [reportTitle, setReportTitle] = useState<string>("");
  const [tradeShows, setTradeShows] = useState<any[]>([]);
  const [selectedTradeShow, setSelectedTradeShow] = useState<number | null>(null);
  const [includeFollowUps, setIncludeFollowUps] = useState(false);
  const [followUpDateRange, setFollowUpDateRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: null,
    to: null,
  });

  const fetchIndustries = async (selectedCategory: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.log("No user found");
        return;
      }

      const uniqueIndustries = await fetchIndustriesForCategory(selectedCategory, user.id);
      setIndustries(uniqueIndustries);
    } catch (error) {
      console.error("Error in fetchIndustries:", error);
      toast({
        title: "Error",
        description: "Failed to load industries",
        variant: "destructive",
      });
    }
  };

  const fetchCompanies = async () => {
    if (!category || !industry) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const companiesData = await fetchCompaniesForIndustry(category, industry, user.id);
      setCompanies(companiesData);
    } catch (error) {
      console.error("Error in fetchCompanies:", error);
      toast({
        title: "Error",
        description: "Failed to load companies",
        variant: "destructive",
      });
    }
  };

  const loadTradeShows = async () => {
    if (category !== "trade_show") return;
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const shows = await fetchTradeShows(user.id);
      setTradeShows(shows);
    } catch (error) {
      console.error("Error loading trade shows:", error);
      toast({
        title: "Error",
        description: "Failed to load trade shows",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (industry) {
      fetchCompanies();
    }
  }, [industry]);

  useEffect(() => {
    if (category === "trade_show") {
      loadTradeShows();
    }
  }, [category]);

  return {
    category,
    setCategory,
    industry,
    setIndustry,
    industries,
    companies,
    selectedCompanies,
    setSelectedCompanies,
    notes,
    setNotes,
    reportTitle,
    setReportTitle,
    tradeShows,
    selectedTradeShow,
    setSelectedTradeShow,
    includeFollowUps,
    setIncludeFollowUps,
    followUpDateRange,
    setFollowUpDateRange,
    fetchIndustries,
    fetchCompanies,
  };
};