import { Calendar } from "@/components/ui/calendar";
import { addMonths, subMonths } from "date-fns";
import { useIsMobile } from "@/hooks/use-mobile";

interface MonthlyCalendarViewProps {
  date: Date;
  setDate: (date: Date) => void;
  sharedCalendarClassNames: any;
}

export const MonthlyCalendarView = ({ date, setDate, sharedCalendarClassNames }: MonthlyCalendarViewProps) => {
  const currentDate = new Date(date);
  const prevMonth = subMonths(currentDate, 1);
  const nextMonth = addMonths(currentDate, 1);
  const isMobile = useIsMobile();

  const prevMonthCalendarClassNames = {
    ...sharedCalendarClassNames,
    day_today: "text-gray-900",
    day_selected: "",
  };

  const nextMonthCalendarClassNames = {
    ...sharedCalendarClassNames,
    day_today: "text-gray-900",
    day_selected: "",
  };

  const centerCalendarClassNames = {
    ...sharedCalendarClassNames,
    caption: "flex justify-center relative items-center text-[0.65rem] h-4 px-2",
    caption_label: "text-[0.65rem] font-medium text-center",
    head_cell: "text-gray-500 w-6 font-normal text-[0.55rem] text-center",
    nav: "hidden",
  };

  return (
    <div className="w-full bg-white p-4">
      <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-3'} gap-8 max-w-6xl w-full mx-auto`}>
        {!isMobile && (
          <div className="flex justify-center">
            <Calendar
              mode="single"
              selected={undefined}
              month={prevMonth}
              onSelect={(newDate) => newDate && setDate(newDate)}
              disabled
              className="w-[220px]"
              classNames={prevMonthCalendarClassNames}
              showWeekNumber
              weekStartsOn={1}
            />
          </div>
        )}
        <div className="flex justify-center">
          <Calendar
            mode="single"
            selected={date}
            month={currentDate}
            onSelect={(newDate) => newDate && setDate(newDate)}
            className="w-[220px]"
            classNames={centerCalendarClassNames}
            showWeekNumber
            weekStartsOn={1}
          />
        </div>
        {!isMobile && (
          <div className="flex justify-center">
            <Calendar
              mode="single"
              selected={undefined}
              month={nextMonth}
              onSelect={(newDate) => newDate && setDate(newDate)}
              disabled
              className="w-[220px]"
              classNames={nextMonthCalendarClassNames}
              showWeekNumber
              weekStartsOn={1}
            />
          </div>
        )}
      </div>
    </div>
  );
};