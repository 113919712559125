import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

interface ReportTitleInputProps {
  title: string;
  onTitleChange: (value: string) => void;
  category: string;
}

const ReportTitleInput = ({ title, onTitleChange, category }: ReportTitleInputProps) => {
  return (
    <div className="space-y-2">
      <Label>Report Title</Label>
      <Input
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        placeholder={`${category ? category.charAt(0).toUpperCase() + category.slice(1) : ''} Report`}
      />
    </div>
  );
};

export default ReportTitleInput;