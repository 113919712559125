import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { cn } from "@/lib/utils";

interface FollowUpDateRangeProps {
  includeFollowUps: boolean;
  dateRange: {
    from: Date | null;
    to: Date | null;
  };
  onIncludeFollowUpsChange: (value: boolean) => void;
  onDateRangeChange: (range: { from: Date | null; to: Date | null }) => void;
}

const FollowUpDateRange = ({
  includeFollowUps,
  dateRange,
  onIncludeFollowUpsChange,
  onDateRangeChange,
}: FollowUpDateRangeProps) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <Switch
          id="include-follow-ups"
          checked={includeFollowUps}
          onCheckedChange={onIncludeFollowUpsChange}
        />
        <Label htmlFor="include-follow-ups">Include Follow-ups</Label>
      </div>

      {includeFollowUps && (
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <Label>From Date</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !dateRange.from && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {dateRange.from ? format(dateRange.from, "PPP") : "Pick a date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={dateRange.from || undefined}
                  onSelect={(date) =>
                    onDateRangeChange({ ...dateRange, from: date })
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className="flex-1">
            <Label>To Date</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !dateRange.to && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {dateRange.to ? format(dateRange.to, "PPP") : "Pick a date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={dateRange.to || undefined}
                  onSelect={(date) =>
                    onDateRangeChange({ ...dateRange, to: date })
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      )}
    </div>
  );
};

export default FollowUpDateRange;