import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useState, useEffect } from "react";

interface AddEditIndustryDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  editIndustry: { id: number; name: string } | null;
  onSuccess: () => Promise<void>;
}

const AddEditIndustryDialog = ({
  isOpen,
  onOpenChange,
  editIndustry,
  onSuccess,
}: AddEditIndustryDialogProps) => {
  const [newIndustry, setNewIndustry] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    if (editIndustry) {
      setNewIndustry(editIndustry.name);
    } else {
      setNewIndustry("");
    }
  }, [editIndustry, isOpen]);

  const handleAddOrUpdateIndustry = async () => {
    if (!newIndustry.trim()) {
      toast({
        title: "Error",
        description: "Please enter an industry name",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to manage industries",
          variant: "destructive",
        });
        return;
      }

      if (editIndustry) {
        // Start a transaction to update both industries and clients
        const { error: industryError } = await supabase
          .from('industries')
          .update({ name: newIndustry })
          .eq('id', editIndustry.id)
          .eq('user_id', session.user.id);

        if (industryError) throw industryError;

        // Update all clients that have the old industry name
        const { error: clientsError } = await supabase
          .from('clients')
          .update({ industry: newIndustry })
          .eq('industry', editIndustry.name)
          .eq('user_id', session.user.id);

        if (clientsError) throw clientsError;

        toast({
          title: "Success",
          description: "Industry updated successfully",
        });
      } else {
        const { error } = await supabase
          .from('industries')
          .insert({
            name: newIndustry,
            color: "bg-blue-500",
            user_id: session.user.id
          });

        if (error) throw error;

        toast({
          title: "Success",
          description: "Industry added successfully",
        });
      }

      setNewIndustry("");
      onOpenChange(false);
      await onSuccess();
    } catch (error: any) {
      console.error('Error managing industry:', error);
      toast({
        title: "Error",
        description: `Failed to ${editIndustry ? 'update' : 'add'} industry`,
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{editIndustry ? 'Edit Industry' : 'Add New Industry'}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 pt-4">
          <Input
            placeholder="Enter industry name"
            value={newIndustry}
            onChange={(e) => setNewIndustry(e.target.value)}
          />
          <Button 
            className="w-full"
            onClick={handleAddOrUpdateIndustry}
          >
            {editIndustry ? 'Update Industry' : 'Add Industry'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditIndustryDialog;