import { Factory, Plus, Pencil, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface IndustryPanelProps {
  industries: string[];
  selectedIndustry: string | null;
  onIndustrySelect: (industry: string) => void;
  onIndustryAdded: () => void;
}

const IndustryPanel = ({ 
  industries, 
  selectedIndustry, 
  onIndustrySelect,
  onIndustryAdded 
}: IndustryPanelProps) => {
  const [isAddingIndustry, setIsAddingIndustry] = useState(false);
  const [newIndustry, setNewIndustry] = useState("");
  const [editingIndustry, setEditingIndustry] = useState<string | null>(null);
  const { toast } = useToast();

  const handleAddIndustry = async () => {
    if (!newIndustry.trim()) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('prospects')
        .insert([
          {
            company: newIndustry,
            industry: newIndustry,
            user_id: user.id
          }
        ]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Industry added successfully",
      });

      setIsAddingIndustry(false);
      setNewIndustry("");
      onIndustryAdded();
    } catch (error) {
      console.error('Error adding industry:', error);
      toast({
        title: "Error",
        description: "Failed to add industry",
        variant: "destructive",
      });
    }
  };

  const handleEditIndustry = async (oldIndustry: string) => {
    if (!editingIndustry || !editingIndustry.trim()) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('prospects')
        .update({ industry: editingIndustry })
        .eq('industry', oldIndustry)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Industry updated successfully",
      });

      setEditingIndustry(null);
      onIndustryAdded();
    } catch (error) {
      console.error('Error updating industry:', error);
      toast({
        title: "Error",
        description: "Failed to update industry",
        variant: "destructive",
      });
    }
  };

  const handleDeleteIndustry = async (industry: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('prospects')
        .delete()
        .eq('industry', industry)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Industry deleted successfully",
      });

      onIndustryAdded();
    } catch (error) {
      console.error('Error deleting industry:', error);
      toast({
        title: "Error",
        description: "Failed to delete industry",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between bg-gray-50/80 border-b p-3">
        <div className="flex items-center gap-2">
          <Factory className="h-5 w-5 text-gray-500" />
          <h2 className="text-lg font-semibold">Industries</h2>
        </div>
        <Dialog open={isAddingIndustry} onOpenChange={setIsAddingIndustry}>
          <DialogTrigger asChild>
            <Button 
              variant="ghost" 
              size="sm"
              className="h-8 w-8"
            >
              <Plus className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Industry</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 pt-4">
              <Input
                placeholder="Enter industry name"
                value={newIndustry}
                onChange={(e) => setNewIndustry(e.target.value)}
              />
              <Button 
                className="w-full"
                onClick={handleAddIndustry}
              >
                Add Industry
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div className="p-2">
        <div className="space-y-0.5">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="flex items-center justify-between group"
            >
              <Button
                variant="ghost"
                className={`w-full justify-start px-2 py-1 h-8 text-sm font-normal hover:bg-gray-50 ${
                  selectedIndustry === industry ? 'bg-gray-50' : ''
                }`}
                onClick={() => onIndustrySelect(industry)}
              >
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-300"></div>
                  <span>{industry}</span>
                </div>
              </Button>
              <div className="flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-7 w-7"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingIndustry(industry);
                      }}
                    >
                      <Pencil className="h-3 w-3" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Edit Industry</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 pt-4">
                      <Input
                        value={editingIndustry || ''}
                        onChange={(e) => setEditingIndustry(e.target.value)}
                        placeholder="Enter industry name"
                      />
                      <Button 
                        className="w-full"
                        onClick={() => handleEditIndustry(industry)}
                      >
                        Update Industry
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-7 w-7"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Trash2 className="h-3 w-3" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the industry.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => handleDeleteIndustry(industry)}>
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustryPanel;