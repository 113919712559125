import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BriefcaseBusiness } from "lucide-react";
import Header from "@/components/Header";

const About = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="p-4">
        <div className="max-w-3xl mx-auto space-y-6">
          <div className="flex justify-center mb-8">
            <img
              src="/lovable-uploads/b9233c0d-7555-460a-afce-1cfe1ffd1ccb.png"
              alt="Rocket"
              className="w-24 h-24 object-contain"
            />
          </div>
          
          <Card>
            <CardHeader className="bg-gray-50 border-b pb-3">
              <div className="flex items-center gap-2">
                <BriefcaseBusiness className="w-5 h-5 text-gray-600" />
                <CardTitle className="text-lg font-medium text-gray-700">About Us</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="p-6 space-y-6">
              <p className="text-gray-600">
                At SalesBy, we believe that sales success doesn't come from textbooks or generic methods—it's built on real-world experience, relentless effort, and the right tools. Created by top sales professionals with years of field experience, SalesBy was born from a deep understanding of the real challenges sales teams face every day.
              </p>
              <p className="text-gray-600">
                We know that no single "sales school" or universal method guarantees success. That's why we developed a cutting-edge, distraction-free sales management platform that streamlines workflows, organizes follow-ups, and helps teams focus on what matters most: building relationships and closing deals.
              </p>
              <p className="text-gray-600">
                SalesBy addresses a critical pain point in sales: the risk of accommodation and distraction. For instance, a lead classified under one industry during prospecting might reveal different needs when they become a client. Our platform's independent industry sections and real-time revision tools help you adapt seamlessly, ensuring your strategy remains on track and your focus sharp.
              </p>

              <div className="py-4">
                <h3 className="text-lg font-medium text-gray-700 mb-3">At SalesBy, we emphasize the importance of working simultaneously across three essential areas of sales while staying organized:</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  <li><span className="font-medium">Sales by Following Up:</span> Building and nurturing strong client relationships.</li>
                  <li><span className="font-medium">Sales by Visiting or Exhibiting at Trade Shows:</span> Turning face-to-face opportunities into results.</li>
                  <li><span className="font-medium">Sales by Prospecting:</span> Identifying and targeting new potential clients.</li>
                </ul>
                <p className="mt-3 text-gray-600">By balancing these three pillars effectively, your productivity will skyrocket, and your sales process will remain streamlined.</p>
              </div>
              
              <div className="py-4">
                <h3 className="text-lg font-medium text-gray-700 mb-3">Key Features</h3>
                <p className="mb-3 text-gray-600">SalesBy is built to simplify and optimize every stage of the sales process with features designed for real-world challenges:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  <li>Industry Customization by Each Stage: Tailor workflows for prospecting, client management, and follow-up to align with specific industry needs.</li>
                  <li>Client Monitoring and Follow-Up System: Stay organized and never lose sight of important client relationships with intelligent tracking tools.</li>
                  <li>Trade Show Organization with Leads Management: Maximize your trade show participation by managing leads efficiently and turning connections into results.</li>
                  <li>Customizable Weekly Calendar: Plan and adjust your schedule dynamically to stay on top of tasks, meetings, and priorities.</li>
                  <li>To-Do List: Stay organized with a flexible task management system designed to help you prioritize effectively and meet deadlines.</li>
                  <li>Comprehensive Reports: Generate three types of reports with extensive customizable options, giving you the ability to analyze data your way and focus on the metrics that matter most.</li>
                </ul>
              </div>

              <div className="py-4">
                <h3 className="text-lg font-medium text-gray-700 mb-3">The Technology Behind SalesBy</h3>
                <p className="mb-3 text-gray-600">SalesBy is powered by modern, scalable technology to deliver a seamless experience and ensure reliability for sales teams:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  <li>Frontend: Built with React and TypeScript, powered by Vite, to ensure a fast, responsive, and user-friendly interface. Tailwind CSS and Shadcn/UI provide sleek, customizable designs that are fully responsive for any device.</li>
                  <li>Backend: Powered by Supabase, featuring a PostgreSQL database for secure and efficient data storage. Row Level Security (RLS) ensures robust data protection, while real-time updates and Edge Functions enable seamless interactions and performance.</li>
                  <li>State Management: Utilizes TanStack Query (React Query) for efficient server state management, paired with React's hooks for local state handling and custom logic.</li>
                  <li>Scalable Design: The entire architecture is designed with scalability in mind, ensuring SalesBy can grow with your business and handle increasing demands effortlessly.</li>
                </ul>
              </div>

              <div className="py-4">
                <h3 className="text-lg font-medium text-gray-700 mb-3">Why Choose SalesBy?</h3>
                <p className="text-gray-600">
                  By combining intelligent technology, intuitive tools, and proven best practices, SalesBy empowers businesses of all sizes to boost productivity, stay organized, and drive results. Whether you're tracking activities, managing client relationships, or fine-tuning your negotiation strategy, SalesBy provides everything you need to succeed in today's competitive market.
                </p>
              </div>

              <p className="text-gray-600">
                SalesBy isn't just software—it's the culmination of vast experience, practical knowledge, and an unwavering commitment to helping sales teams perform at their best.
              </p>
              
              <p className="text-gray-700 font-medium text-center mt-8">
                SalesBy: Work Smarter. Stay Focused. Close More Deals.
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default About;