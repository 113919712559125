import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RegistrationComplete from "./pages/RegistrationComplete";
import Index from "./pages/Index";
import Success from "./pages/Success";
import Plans from "./pages/Plans";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Landing from "./pages/Landing";
import TradeShows from "./pages/TradeShows";
import Prospecting from "./pages/Prospecting";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Register from "./pages/Register";
import Reports from "./pages/Reports";
import SignUp from "./pages/SignUp";
import Credentials from "./pages/Credentials";
import RocketAnimation from "./pages/RocketAnimation";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoute from "./components/auth/ProtectedRoute";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/success" element={<Success />} />
        <Route path="/credentials" element={<Credentials />} />
        <Route path="/rocket" element={<RocketAnimation />} />
        <Route path="/registration-complete" element={<RegistrationComplete />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/trade-shows"
          element={
            <ProtectedRoute>
              <TradeShows />
            </ProtectedRoute>
          }
        />
        <Route
          path="/prospecting"
          element={
            <ProtectedRoute>
              <Prospecting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;