import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Header from "@/components/Header";

const RocketAnimation = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-black overflow-hidden relative">
      <Header />
      <div className="container mx-auto px-4 h-[calc(100vh-64px)] relative">
        <motion.div
          initial={{ x: -100, y: "100vh", opacity: 0 }}
          animate={{
            x: "100vw",
            y: -100,
            opacity: [0, 1, 1, 0],
            rotate: 45,
          }}
          transition={{
            duration: 3,
            ease: "easeInOut",
            times: [0, 0.2, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          className="absolute"
        >
          <span className="text-6xl">🚀</span>
        </motion.div>
        <div className="flex flex-col items-center justify-center h-full text-white space-y-8">
          <h1 className="text-4xl font-bold text-center animate-fade-up">
            Watch the Rocket Fly!
          </h1>
          <p className="text-xl text-center max-w-2xl animate-fade-up">
            A simple demonstration of animation using Framer Motion
          </p>
        </div>
      </div>
    </div>
  );
};

export default RocketAnimation;