import { useState } from "react";
import IndustriesSection from "./IndustriesSection";
import TradeShowsSection from "./TradeShowsSection";

interface TradeShowsPanelProps {
  selectedIndustry: string | null;
  onIndustrySelect: (industry: string) => void;
  onAddClick: () => void;
  refreshTrigger: number;
}

const TradeShowsPanel = ({
  selectedIndustry,
  onIndustrySelect,
  onAddClick,
  refreshTrigger,
}: TradeShowsPanelProps) => {
  return (
    <div className="space-y-4">
      <IndustriesSection
        selectedIndustry={selectedIndustry}
        onIndustrySelect={onIndustrySelect}
      />

      <TradeShowsSection
        selectedIndustry={selectedIndustry}
        refreshTrigger={refreshTrigger}
        onAddClick={onAddClick}
      />
    </div>
  );
};

export default TradeShowsPanel;