import { X } from "lucide-react";
import { CalendarEvent } from "@/types/calendar";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useState, useCallback, useEffect } from "react";
import { useIsMobile } from "@/hooks/use-mobile";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";

interface CalendarTableProps {
  weekDays: string[];
  activeWeeks: number[];
  events: CalendarEvent[];
  handleDeleteEvent: (week: number, day: string, text: string) => void;
  handleStatusChange: (week: number, day: string, text: string) => void;
  isLoading?: boolean;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "done":
      return "bg-green-500";
    case "in-process":
      return "bg-yellow-500";
    case "pending":
      return "bg-red-500";
    case "gray":
      return "bg-gray-500";
    case "blue":
      return "bg-blue-500";
    case "orange":
      return "bg-orange-500";
    default:
      return "bg-gray-500";
  }
};

const CalendarTable = ({ 
  weekDays, 
  activeWeeks, 
  events, 
  handleDeleteEvent, 
  handleStatusChange,
  isLoading = false 
}: CalendarTableProps) => {
  const [localEvents, setLocalEvents] = useState<CalendarEvent[]>([]);
  const [editingEvent, setEditingEvent] = useState<{ week: number; day: string; text: string } | null>(null);
  const [editText, setEditText] = useState("");
  const isMobile = useIsMobile();
  const { toast } = useToast();

  useEffect(() => {
    setLocalEvents(events);
  }, [events]);

  const handleDoubleClick = (week: number, day: string, text: string) => {
    setEditingEvent({ week, day, text });
    setEditText(text);
  };

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingEvent || !editText.trim()) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('calendar_events')
        .update({ text: editText.trim() })
        .eq('user_id', user.id)
        .eq('week', editingEvent.week)
        .eq('day', editingEvent.day)
        .eq('text', editingEvent.text);

      if (error) {
        console.error('Error updating event:', error);
        toast({
          title: "Error",
          description: "Failed to update event",
          variant: "destructive",
        });
        return;
      }

      setLocalEvents(prev => prev.map(event => {
        if (event.week === editingEvent.week && 
            event.day === editingEvent.day && 
            event.text === editingEvent.text) {
          return { ...event, text: editText.trim() };
        }
        return event;
      }));

      toast({
        title: "Success",
        description: "Event updated successfully",
      });
    } catch (error) {
      console.error('Error in handleEditSubmit:', error);
    } finally {
      setEditingEvent(null);
      setEditText("");
    }
  };

  const updateEventInDatabase = async (event: CalendarEvent, newWeek: number, newDay: string, allDayEvents: CalendarEvent[]) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return false;

      // If moving to a different day
      if (event.week !== newWeek || event.day !== newDay) {
        const { error: moveError } = await supabase
          .from('calendar_events')
          .update({
            week: newWeek,
            day: newDay,
            order: allDayEvents.findIndex(e => e.text === event.text)
          })
          .eq('user_id', user.id)
          .eq('week', event.week)
          .eq('day', event.day)
          .eq('text', event.text);

        if (moveError) {
          console.error('Error moving event:', moveError);
          return false;
        }
      }

      // Update orders for all events in the destination day
      for (let i = 0; i < allDayEvents.length; i++) {
        const dayEvent = allDayEvents[i];
        const { error } = await supabase
          .from('calendar_events')
          .update({ order: i })
          .eq('user_id', user.id)
          .eq('week', newWeek)
          .eq('day', newDay)
          .eq('text', dayEvent.text);

        if (error) {
          console.error('Error updating event order:', error);
          return false;
        }
      }

      return true;
    } catch (error) {
      console.error('Error in updateEventInDatabase:', error);
      return false;
    }
  };

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const [sourceWeek, sourceDay] = source.droppableId.split('-');
    const [destWeek, destDay] = destination.droppableId.split('-');

    const newEvents = [...localEvents];
    
    // Find the moved event
    const movedEvent = newEvents.find(
      e => e.week === parseInt(sourceWeek) && e.day === sourceDay && 
      `${e.week}-${e.day}-${e.text}` === result.draggableId
    );

    if (!movedEvent) return;

    // Remove the event from its original position
    const filteredEvents = newEvents.filter(
      e => !(e.week === parseInt(sourceWeek) && e.day === sourceDay && e.text === movedEvent.text)
    );

    // Get all events for the destination day, maintaining their current order
    let destEvents = filteredEvents.filter(
      e => e.week === parseInt(destWeek) && e.day === destDay
    );

    // Create the updated event
    const updatedEvent = {
      ...movedEvent,
      week: parseInt(destWeek),
      day: destDay,
      order: destination.index
    };

    // Insert the event at its new position
    destEvents.splice(destination.index, 0, updatedEvent);

    // Update the database
    const success = await updateEventInDatabase(
      movedEvent,
      parseInt(destWeek),
      destDay,
      destEvents
    );

    if (!success) {
      toast({
        title: "Error",
        description: "Failed to update event position",
        variant: "destructive",
      });
      return;
    }

    // Update local state
    const finalEvents = [
      ...filteredEvents.filter(e => !(e.week === parseInt(destWeek) && e.day === destDay)),
      ...destEvents
    ];

    setLocalEvents(finalEvents);

    toast({
      title: "Success",
      description: "Event position updated",
    });
  }, [localEvents, toast]);

  if (isLoading) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <table className="w-full border-collapse table-fixed">
        <tbody className="divide-y">
          {activeWeeks.map((weekNum) => (
            <tr key={weekNum} className="divide-x">
              <td className="w-8 p-0.5 text-center bg-gray-50 font-medium text-gray-600 text-xs">
                {weekNum}
              </td>
              {weekDays.map((day) => {
                const dayEvents = localEvents
                  .filter(e => e.week === weekNum && e.day === day)
                  .sort((a, b) => (a.order || 0) - (b.order || 0));
                
                return (
                  <td
                    key={day}
                    className="p-0.5 text-left hover:bg-gray-50 transition-colors text-xs relative group"
                  >
                    <Droppable droppableId={`${weekNum}-${day}`}>
                      {(provided) => (
                        <div 
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="flex flex-col gap-0.5 min-h-[1.5rem]"
                        >
                          {dayEvents.length > 0 ? (
                            dayEvents.map((event, index) => (
                              <Draggable 
                                key={`${event.week}-${event.day}-${event.text}`}
                                draggableId={`${event.week}-${event.day}-${event.text}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="flex items-center gap-1 cursor-move touch-manipulation group/event h-5"
                                    onDoubleClick={() => handleDoubleClick(event.week, event.day, event.text)}
                                  >
                                    <div
                                      onClick={() => handleStatusChange(weekNum, day, event.text)}
                                      className={`h-2 w-2 rounded-full ${getStatusColor(event.status)} cursor-pointer hover:opacity-80 transition-opacity`}
                                      role="button"
                                      tabIndex={0}
                                      aria-label="Change status"
                                    />
                                    {editingEvent?.week === event.week && 
                                     editingEvent?.day === event.day && 
                                     editingEvent?.text === event.text ? (
                                      <form onSubmit={handleEditSubmit} className="flex-1">
                                        <Input
                                          value={editText}
                                          onChange={(e) => setEditText(e.target.value)}
                                          onBlur={handleEditSubmit}
                                          autoFocus
                                          maxLength={14}
                                          className="h-4 text-xs py-0 px-1"
                                        />
                                      </form>
                                    ) : (
                                      <>
                                        <span className="truncate flex-1 leading-5">{event.text}</span>
                                        <button
                                          onClick={() => handleDeleteEvent(weekNum, day, event.text)}
                                          className="invisible group-hover/event:visible w-3 h-3 flex items-center justify-center text-gray-400 hover:text-red-500 transition-colors mr-1"
                                          aria-label="Delete event"
                                        >
                                          <X className="h-2.5 w-2.5" />
                                        </button>
                                      </>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <div className="h-5">{"\u00A0"}</div>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </DragDropContext>
  );
};

export default CalendarTable;
