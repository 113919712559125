import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { RegistrationForm } from "@/components/success/RegistrationForm";

const Success = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const setupToken = searchParams.get("setup_token") || "";
  const sessionId = searchParams.get("session_id") || "";
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    passcode: "",
    confirmPasscode: ""
  });

  useEffect(() => {
    const verifySetupToken = async () => {
      if (!setupToken || !sessionId) {
        toast({
          title: "Invalid Access",
          description: "Missing required parameters.",
          variant: "destructive",
        });
        navigate('/');
        return;
      }

      try {
        // Verify the setup token exists in pending_subscriptions
        const { data: subscription, error } = await supabase
          .from('pending_subscriptions')
          .select('subscription_status, email')
          .eq('setup_token', setupToken)
          .single();

        if (error || !subscription) {
          throw new Error("Invalid or expired setup token");
        }

        // If subscription is active, check profile status
        if (subscription.subscription_status === 'active') {
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('subscription_status, registration_completed')
            .eq('email', subscription.email)
            .single();

          if (!profileError && profile) {
            // If profile is active and registration is completed, redirect to dashboard
            if (profile.subscription_status === 'active' && profile.registration_completed) {
              toast({
                title: "Success",
                description: "Your subscription is active. Redirecting to dashboard...",
              });
              navigate('/dashboard');
              return;
            }
          }
        }

        setFormData(prev => ({
          ...prev,
          email: subscription.email
        }));
        
        setLoading(false);
      } catch (error: any) {
        console.error('Setup token verification error:', error);
        toast({
          title: "Invalid Setup Token",
          description: "This registration link is invalid or has expired.",
          variant: "destructive",
        });
        navigate('/');
      }
    };

    verifySetupToken();
  }, [setupToken, sessionId, navigate, toast]);

  const handleRegistrationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (formData.passcode !== formData.confirmPasscode) {
      toast({
        title: "Error",
        description: "Passcodes do not match",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { session }, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.passcode,
      });

      if (error) throw error;

      if (session) {
        // Update the profile with additional information
        const { error: profileError } = await supabase
          .from('profiles')
          .update({
            username: formData.username,
            registration_completed: true,
          })
          .eq('id', session.user.id);

        if (profileError) throw profileError;

        toast({
          title: "Registration Complete",
          description: "You can now access your account.",
        });
        navigate("/dashboard");
      }
    } catch (error: any) {
      console.error("Registration error:", error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const handleFormChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center p-4">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Verifying your registration...</h1>
          <p className="text-muted-foreground">Please wait while we verify your information.</p>
        </div>
      </div>
    );
  }

  return (
    <RegistrationForm 
      formData={formData}
      handleChange={handleFormChange}
      handleSubmit={handleRegistrationSubmit}
    />
  );
};

export default Success;