import { TradeShowContact } from "@/types/tradeShow";
import ContactListItem from "../../contacts/ContactListItem";

interface ContactsListProps {
  contacts: TradeShowContact[];
  onEdit: (contact: TradeShowContact) => void;
  onDelete: (contactId: number) => void;
  onContactClick: (contact: TradeShowContact) => void;
}

const ContactsList = ({
  contacts,
  onEdit,
  onDelete,
  onContactClick,
}: ContactsListProps) => {
  return (
    <div className="divide-y">
      {contacts.map((contact) => (
        <ContactListItem
          key={contact.id}
          contact={contact}
          onEdit={onEdit}
          onDelete={onDelete}
          onClick={() => onContactClick(contact)}
        />
      ))}
      {contacts.length === 0 && (
        <div className="py-4 text-center text-sm text-gray-500">
          No contacts found
        </div>
      )}
    </div>
  );
};

export default ContactsList;