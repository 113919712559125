import { useState } from "react";
import { FollowUp } from "@/types/followUp";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Client } from "@/types/client";

export const useFollowUps = (selectedClient: Client | null) => {
  const [followUps, setFollowUps] = useState<FollowUp[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof FollowUp; direction: 'asc' | 'desc' } | null>(null);
  const { toast } = useToast();

  const fetchFollowUps = async () => {
    if (!selectedClient) {
      setFollowUps([]);
      return;
    }

    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user?.id) return;

      const { data, error } = await supabase
        .from('follow_ups')
        .select('*')
        .eq('user_id', session.session.user.id)
        .eq('client_id', selectedClient.id)
        .order('created_at', { ascending: false });

      if (error) {
        toast({
          title: "Error",
          description: "Failed to fetch follow-ups",
          variant: "destructive",
        });
        return;
      }

      if (data) {
        // Ensure the data matches the FollowUp type
        const typedData = data.map(item => ({
          ...item,
          status: item.status as FollowUp['status'],
          priority: item.priority as FollowUp['priority']
        }));
        setFollowUps(typedData);
      }
    } catch (error) {
      console.error('Error fetching follow-ups:', error);
      toast({
        title: "Error",
        description: "Failed to fetch follow-ups",
        variant: "destructive",
      });
    }
  };

  const handleSort = (key: keyof FollowUp) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig?.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortedFollowUps = () => {
    return [...followUps].sort((a, b) => {
      if (!sortConfig) {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        
        if (dateA.getTime() !== dateB.getTime()) {
          return dateB.getTime() - dateA.getTime();
        }
        
        if (a.status === b.status) return 0;
        if (a.status === 'pending') return -1;
        if (b.status === 'pending') return 1;
        if (a.status === 'completed') return 1;
        if (b.status === 'completed') return -1;
        return 0;
      }
      
      if (sortConfig.key === 'date') {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortConfig.direction === 'asc' 
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      if (aValue === bValue) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;
      
      const comparison = aValue < bValue ? -1 : 1;
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    });
  };

  return {
    followUps: getSortedFollowUps(),
    sortConfig,
    handleSort,
    fetchFollowUps
  };
};