import { FileText } from "lucide-react";
import ProspectNotes from "../ProspectNotes";

interface ProspectDetailsPanelProps {
  selectedProspect: number | null;
}

const ProspectDetailsPanel = ({ selectedProspect }: ProspectDetailsPanelProps) => {
  if (!selectedProspect) {
    return (
      <div className="p-8 text-center text-gray-500">
        Select a prospect to view details and add notes
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center gap-2 bg-gray-50/80 border-b p-4">
        <FileText className="h-5 w-5 text-gray-500" />
        <h2 className="font-semibold text-gray-700">Notes</h2>
      </div>
      <div className="p-4">
        <ProspectNotes prospectId={selectedProspect} />
      </div>
    </div>
  );
};

export default ProspectDetailsPanel;