import { supabase } from "@/integrations/supabase/client";

export const fetchIndustriesForCategory = async (selectedCategory: string, userId: string) => {
  console.log('Fetching industries for category:', selectedCategory);
  
  let query;
  switch (selectedCategory) {
    case "prospect":
      query = supabase
        .from("prospects")
        .select("industry")
        .eq("user_id", userId)
        .not("industry", "is", null);
      break;
    case "trade_show":
      query = supabase
        .from("trade_show_industries")
        .select("industry_name")
        .eq("user_id", userId);
      break;
    case "dashboard":
      query = supabase
        .from("industries")
        .select("name")
        .eq("user_id", userId);
      break;
    default:
      throw new Error(`Invalid category: ${selectedCategory}`);
  }

  const { data, error } = await query;
  console.log('Query result:', { data, error });
  
  if (error) throw error;

  if (selectedCategory === "dashboard") {
    return Array.from(
      new Set(
        (data as { name: string }[])
          .map(item => item.name)
          .filter((name): name is string => name !== null)
      )
    );
  } else if (selectedCategory === "trade_show") {
    return Array.from(
      new Set(
        (data as { industry_name: string }[])
          .map(item => item.industry_name)
          .filter((name): name is string => name !== null)
      )
    );
  }

  return Array.from(
    new Set(
      (data as { industry: string }[])
        .map(item => item.industry)
        .filter((industry): industry is string => industry !== null)
    )
  );
};

export const fetchCompaniesForIndustry = async (category: string, industry: string, userId: string) => {
  console.log('Fetching companies with:', { category, industry, userId });
  
  let query;
  switch (category) {
    case "prospect":
      query = supabase
        .from("prospects")
        .select("id, company, contact_name")
        .eq("user_id", userId)
        .eq("industry", industry);
      break;
    case "trade_show":
      query = supabase
        .from("trade_show_contact_industries")
        .select(`
          contact_id,
          trade_show_contacts!inner(
            id,
            company,
            contact
          )
        `)
        .eq("user_id", userId)
        .eq("industry_name", industry);
      break;
    case "dashboard":
      query = supabase
        .from("clients")
        .select("id, company, contact")
        .eq("user_id", userId)
        .eq("industry", industry);
      break;
    default:
      throw new Error(`Invalid category: ${category}`);
  }

  const { data, error } = await query;
  console.log('Query result for companies:', { data, error });
  
  if (error) throw error;
  
  // Transform the data to match the expected format
  if (category === "trade_show") {
    return (data || []).map(item => ({
      id: item.trade_show_contacts.id,
      company: item.trade_show_contacts.company,
      contact: item.trade_show_contacts.contact
    }));
  }
  
  return (data || []).map(item => ({
    id: item.id,
    company: item.company,
    contact: item.contact_name || item.contact
  }));
};

export const fetchTradeShows = async (userId: string) => {
  console.log('Fetching trade shows for user:', userId);
  
  const { data, error } = await supabase
    .from("trade_shows")
    .select("id, name, date, city")
    .eq("user_id", userId);

  if (error) throw error;
  
  return data || [];
};

export const fetchFollowUps = async (clientIds: number[], fromDate: Date, toDate: Date) => {
  const { data: session } = await supabase.auth.getSession();
  if (!session?.session?.user?.id) throw new Error("No authenticated user");

  const { data, error } = await supabase
    .from('follow_ups')
    .select('*')
    .eq('user_id', session.session.user.id)
    .in('client_id', clientIds)
    .gte('date', fromDate.toISOString().split('T')[0])
    .lte('date', toDate.toISOString().split('T')[0])
    .order('date', { ascending: true });

  if (error) throw error;
  return { data };
};
