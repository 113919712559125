import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface ColorPickerProps {
  currentColor: string;
  onColorSelect: (color: string) => void;
}

const ColorPicker = ({ currentColor, onColorSelect }: ColorPickerProps) => {
  const colors = [
    { name: "Yellow", class: "bg-yellow-500" },
    { name: "Red", class: "bg-red-500" },
    { name: "Blue", class: "bg-blue-500" },
    { name: "Green", class: "bg-green-500" },
    { name: "Gray", class: "bg-gray-500" },
    { name: "Orange", class: "bg-orange-500" },
  ];

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          className="h-4 w-4 p-0 hover:bg-transparent"
          onClick={(e) => e.stopPropagation()}
        >
          <div className={`h-2 w-2 rounded-full ${currentColor}`} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit p-2" onClick={(e) => e.stopPropagation()}>
        <div className="flex gap-2">
          {colors.map((color) => (
            <Button
              key={color.name}
              variant="ghost"
              className="h-6 w-6 p-0 hover:bg-transparent"
              onClick={() => onColorSelect(color.class)}
            >
              <div className={`h-3 w-3 rounded-full ${color.class}`} />
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;