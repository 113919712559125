import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";

interface ReportGenerateButtonProps {
  onGenerateReport: () => void;
  isDisabled: boolean;
}

const ReportGenerateButton = ({ onGenerateReport, isDisabled }: ReportGenerateButtonProps) => {
  return (
    <Button
      onClick={onGenerateReport}
      disabled={isDisabled}
      className="w-full"
    >
      <Download className="w-4 h-4 mr-2" />
      Generate Report
    </Button>
  );
};

export default ReportGenerateButton;