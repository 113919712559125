import { Mail, Lock, User } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useRegistration } from "./RegistrationContext";
import { DisclaimerCheckbox } from "./DisclaimerCheckbox";

export const RegistrationForm = () => {
  const { 
    formData, 
    loading, 
    disclaimerAccepted,
    handleFormChange, 
    handleDisclaimerChange,
    handleSubmit 
  } = useRegistration();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2">
        <Label htmlFor="name">Full Name</Label>
        <div className="relative">
          <User className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
          <Input
            id="name"
            type="text"
            placeholder="John Doe"
            className="pl-10"
            value={formData.name}
            onChange={(e) => handleFormChange("name", e.target.value)}
            required
            disabled={loading}
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="email">Email</Label>
        <div className="relative">
          <Mail className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
          <Input
            id="email"
            type="email"
            placeholder="you@example.com"
            className="pl-10"
            value={formData.email}
            onChange={(e) => handleFormChange("email", e.target.value)}
            required
            disabled={loading}
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="password">Password</Label>
        <div className="relative">
          <Lock className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
          <Input
            id="password"
            type="password"
            placeholder="••••••••"
            className="pl-10"
            value={formData.password}
            onChange={(e) => handleFormChange("password", e.target.value)}
            required
            minLength={6}
            disabled={loading}
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="confirmPassword">Confirm Password</Label>
        <div className="relative">
          <Lock className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
          <Input
            id="confirmPassword"
            type="password"
            placeholder="••••••••"
            className="pl-10"
            value={formData.confirmPassword}
            onChange={(e) => handleFormChange("confirmPassword", e.target.value)}
            required
            minLength={6}
            disabled={loading}
          />
        </div>
      </div>

      <DisclaimerCheckbox 
        checked={disclaimerAccepted}
        onCheckedChange={handleDisclaimerChange}
      />

      <Button type="submit" className="w-full" disabled={loading || !disclaimerAccepted}>
        {loading ? "Creating Account..." : "Create Account"}
      </Button>
    </form>
  );
};