import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { TradeShowContact } from "@/types/tradeShow";
import { useToast } from "@/hooks/use-toast";

export const useContactsData = (selectedIndustry: string | null = null) => {
  const [contacts, setContacts] = useState<TradeShowContact[]>([]);
  const { toast } = useToast();

  const fetchContacts = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      let query = supabase
        .from("trade_show_contacts")
        .select(`
          *,
          trade_shows (
            name
          ),
          trade_show_contact_industries!inner (
            industry_name
          )
        `)
        .eq("user_id", user.id);

      // Only apply industry filter if an industry is selected
      if (selectedIndustry) {
        query = query.eq('trade_show_contact_industries.industry_name', selectedIndustry);
      }

      const { data: contactsData, error } = await query
        .order("created_at", { ascending: false });

      if (error) throw error;

      // Process the contacts data to include industries
      const processedContacts = contactsData.map(contact => ({
        ...contact,
        industries: contact.trade_show_contact_industries?.map(i => i.industry_name)
      }));

      setContacts(processedContacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleDeleteContact = async (contactId: number) => {
    try {
      const { error } = await supabase
        .from("trade_show_contacts")
        .delete()
        .eq("id", contactId);

      if (error) throw error;

      toast({
        title: "Contact deleted",
        description: "The contact has been successfully removed.",
      });

      fetchContacts();
    } catch (error) {
      console.error("Error deleting contact:", error);
      toast({
        title: "Error",
        description: "Failed to delete the contact. Please try again.",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchContacts();

    const channel = supabase
      .channel('trade_show_contacts_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'trade_show_contacts'
        },
        () => {
          fetchContacts();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [selectedIndustry]); // Add selectedIndustry as a dependency

  return {
    contacts,
    handleDeleteContact,
    fetchContacts
  };
};