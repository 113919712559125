import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarFormData } from "@/types/calendar";

interface CalendarEventFormProps {
  formData: CalendarFormData;
  setFormData: (data: CalendarFormData) => void;
  handleSubmit: (e: React.FormEvent) => void;
  weekDays: string[];
}

const CalendarEventForm = ({ formData, setFormData, handleSubmit, weekDays }: CalendarEventFormProps) => {
  return (
    <form onSubmit={handleSubmit} className="flex items-start gap-1.5 p-1.5 bg-gray-50 border-b">
      <Input 
        type="number" 
        placeholder="Week"
        className="w-24 h-8 text-sm text-gray-600 text-left"
        value={formData.weekNumber}
        onChange={(e) => {
          const num = parseInt(e.target.value);
          if (!isNaN(num) && num >= 1 && num <= 53) {
            setFormData({ ...formData, weekNumber: e.target.value });
          }
        }}
      />
      <Select 
        value={formData.day}
        onValueChange={(value) => setFormData({ ...formData, day: value })}
      >
        <SelectTrigger className="w-24 h-8 text-sm text-gray-600 text-left">
          <SelectValue placeholder="Day" />
        </SelectTrigger>
        <SelectContent>
          {weekDays.map((day) => (
            <SelectItem key={day} value={day}>
              {day}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Input 
        maxLength={14}
        placeholder="Event name"
        className="w-40 h-8 text-sm text-gray-600 text-left"
        value={formData.event}
        onChange={(e) => setFormData({ ...formData, event: e.target.value })}
      />
      <Button 
        type="submit"
        size="sm"
        className="h-8 bg-gray-700 hover:bg-gray-800 text-white text-sm"
        disabled={!formData.weekNumber || !formData.day || !formData.event}
      >
        Add
      </Button>
    </form>
  );
};

export default CalendarEventForm;