import { FollowUp } from "@/types/followUp";
import FollowUpEditableCell from "./FollowUpEditableCell";

interface FollowUpMobileDetailsProps {
  followUp: FollowUp;
  onUpdate?: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
}

const FollowUpMobileDetails = ({ followUp, onUpdate }: FollowUpMobileDetailsProps) => {
  return (
    <tr className="bg-gray-50">
      <td colSpan={5} className="p-4">
        <div className="space-y-4">
          <div>
            <label className="text-xs font-medium text-gray-500">Description</label>
            <div className="mt-1">
              {onUpdate ? (
                <FollowUpEditableCell
                  value={followUp.description}
                  onSave={(value) => onUpdate(followUp.id, 'description', value)}
                  type="textarea"
                />
              ) : (
                <div className="text-sm">{followUp.description}</div>
              )}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium text-gray-500">Next Step</label>
            <div className="mt-1">
              {onUpdate ? (
                <FollowUpEditableCell
                  value={followUp.next_step}
                  onSave={(value) => onUpdate(followUp.id, 'next_step', value)}
                  type="textarea"
                />
              ) : (
                <div className="text-sm">{followUp.next_step}</div>
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FollowUpMobileDetails;