import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Factory, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import AddEditIndustryDialog from "./industries/AddEditIndustryDialog";
import DeleteConfirmDialog from "./industries/DeleteConfirmDialog";
import IndustrySelect from "./industries/IndustrySelect";
import { useIsMobile } from "@/hooks/use-mobile";

interface IndustriesPanelProps {
  onIndustrySelect: (industry: string) => void;
  selectedIndustry?: string;
}

const IndustriesPanel = ({ onIndustrySelect, selectedIndustry }: IndustriesPanelProps) => {
  const [industries, setIndustries] = useState<{ id: number; name: string; color: string; }[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [industryToDelete, setIndustryToDelete] = useState<number | null>(null);
  const [editIndustry, setEditIndustry] = useState<{ id: number; name: string } | null>(null);
  const { toast } = useToast();
  const isMobile = useIsMobile();

  const fetchIndustries = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to view industries",
          variant: "destructive",
        });
        return;
      }

      const { data, error } = await supabase
        .from('industries')
        .select('*')
        .eq('user_id', session.user.id)
        .order('name');

      if (error) {
        console.error('Error fetching industries:', error);
        throw error;
      }

      console.log('Fetched industries:', data);
      setIndustries(data || []);
    } catch (error: any) {
      console.error('Error fetching industries:', error);
      toast({
        title: "Error",
        description: "Failed to fetch industries",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchIndustries();

    const channel = supabase
      .channel('industries-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'industries'
        },
        () => {
          console.log('Industries changed, refreshing...');
          fetchIndustries();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const handleDeleteIndustry = async () => {
    if (!industryToDelete) return;

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Error",
          description: "You must be logged in to delete industries",
          variant: "destructive",
        });
        return;
      }

      const { error } = await supabase
        .from('industries')
        .delete()
        .eq('id', industryToDelete)
        .eq('user_id', session.user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Industry deleted successfully",
      });
      
      setDeleteConfirmOpen(false);
      setIndustryToDelete(null);
      await fetchIndustries();
    } catch (error: any) {
      console.error('Error deleting industry:', error);
      toast({
        title: "Error",
        description: "Failed to delete industry",
        variant: "destructive",
      });
    }
  };

  const handleEditClick = (id: number, name: string) => {
    console.log('Edit clicked:', { id, name });
    setEditIndustry({ id, name });
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    console.log('Delete clicked:', id);
    setIndustryToDelete(id);
    setDeleteConfirmOpen(true);
  };

  return (
    <Card className="border shadow-sm w-full">
      <CardHeader className="bg-gray-50/80 border-b p-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Factory className="h-5 w-5 text-gray-500" />
            <CardTitle className="text-lg">Industries</CardTitle>
          </div>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-8 w-8 p-0"
            onClick={() => {
              setEditIndustry(null);
              setIsOpen(true);
            }}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-2">
        <IndustrySelect
          industries={industries}
          onIndustrySelect={onIndustrySelect}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          selectedIndustry={selectedIndustry}
        />
      </CardContent>

      <AddEditIndustryDialog
        isOpen={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
          if (!open) {
            setEditIndustry(null);
          }
        }}
        editIndustry={editIndustry}
        onSuccess={fetchIndustries}
      />

      <DeleteConfirmDialog
        isOpen={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onConfirm={handleDeleteIndustry}
      />
    </Card>
  );
};

export default IndustriesPanel;