import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";

interface ShowIndustryItemProps {
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  onDeleteClick: () => void;
}

const ShowIndustryItem = ({
  name,
  isSelected,
  onSelect,
  onDeleteClick,
}: ShowIndustryItemProps) => {
  return (
    <div
      onClick={onSelect}
      className={`flex items-center gap-1.5 py-0.5 px-1 rounded cursor-pointer hover:bg-gray-100 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
      <div className="w-1.5 h-1.5 rounded-full bg-gray-500" />
      <span className="text-xs flex-grow">{name}</span>
      <Button
        variant="ghost"
        size="icon"
        className="h-5 w-5 hover:bg-red-100"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick();
        }}
      >
        <Trash2 className="h-3 w-3 text-gray-500" />
      </Button>
    </div>
  );
};

export default ShowIndustryItem;