import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import CategorySelector from "./form/CategorySelector";
import IndustrySelector from "./form/IndustrySelector";
import CompanySelector from "./form/CompanySelector";
import FollowUpDateRange from "./form/FollowUpDateRange";
import ReportTitleInput from "./form/ReportTitleInput";
import ReportGenerateButton from "./form/ReportGenerateButton";
import { useReportData } from "./hooks/useReportData";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchFollowUps } from "./utils/reportQueries";

const ReportForm = () => {
  const { toast } = useToast();
  const {
    category,
    setCategory,
    industry,
    setIndustry,
    industries,
    companies,
    selectedCompanies,
    setSelectedCompanies,
    notes,
    setNotes,
    reportTitle,
    setReportTitle,
    tradeShows,
    selectedTradeShow,
    setSelectedTradeShow,
    includeFollowUps,
    setIncludeFollowUps,
    followUpDateRange,
    setFollowUpDateRange,
    fetchIndustries,
  } = useReportData();

  const handleCategoryChange = (value: string) => {
    setCategory(value);
    setIndustry("");
    setSelectedCompanies([]);
    setSelectedTradeShow(null);
    fetchIndustries(value);
  };

  const handleCompanyToggle = (companyId: number) => {
    setSelectedCompanies((prev) =>
      prev.includes(companyId)
        ? prev.filter((id) => id !== companyId)
        : [...prev, companyId]
    );
  };

  const generateReport = async () => {
    try {
      const selectedData = companies.filter((company) =>
        selectedCompanies.includes(company.id)
      );

      const transformedCompanies = selectedData.map(company => ({
        company: company.company
      }));

      const selectedShow = tradeShows.find(show => show.id === selectedTradeShow);

      let followUps = [];
      if (category === "dashboard" && includeFollowUps && followUpDateRange.from && followUpDateRange.to) {
        const { data: followUpsData } = await fetchFollowUps(
          selectedCompanies,
          followUpDateRange.from,
          followUpDateRange.to
        );
        followUps = followUpsData || [];
      }

      const report = {
        title: reportTitle || `${category.charAt(0).toUpperCase() + category.slice(1)} Report`,
        category,
        industry,
        companies: transformedCompanies,
        tradeShow: category === "trade_show" ? selectedShow : null,
        followUps: category === "dashboard" ? followUps : [],
        followUpDateRange: category === "dashboard" ? followUpDateRange : null,
        notes,
        generatedAt: new Date().toISOString(),
      };

      const printWindow = window.open('', '_blank');
      if (printWindow) {
        printWindow.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <title>${report.title}</title>
              <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
              <style>
                @media print {
                  body { margin: 0; padding: 20px; }
                  @page { margin: 20px; }
                }
              </style>
            </head>
            <body>
              <div id="report"></div>
              <script>
                const reportData = ${JSON.stringify(report)};
                const reportDiv = document.getElementById('report');
                
                const content = \`
                  <div class="p-8 max-w-4xl mx-auto print:block">
                    <div class="space-y-6">
                      <h1 class="text-3xl font-bold">\${reportData.title}</h1>
                      \${reportData.tradeShow ? 
                        '<h2 class="text-xl text-gray-600 mt-2">' + 
                        reportData.tradeShow.name + ' - ' + reportData.tradeShow.date +
                        '</h2>' : ''}
                      <div class="space-y-2">
                        <p class="text-gray-600">Date: \${new Date(reportData.generatedAt).toLocaleDateString()}</p>
                        <p class="text-gray-600">Industry: \${reportData.industry}</p>
                      </div>

                      <div class="space-y-4">
                        <h2 class="text-xl font-semibold">Companies</h2>
                        <ul class="list-disc pl-5 space-y-2">
                          \${reportData.companies.map((company) => 
                            '<li class="text-gray-600">' + company.company + '</li>'
                          ).join('')}
                        </ul>
                      </div>

                      \${reportData.followUps && reportData.followUps.length > 0 ? 
                        '<div class="space-y-4">' +
                        '<h2 class="text-xl font-semibold">Follow-ups</h2>' +
                        '<div class="divide-y">' +
                        reportData.followUps.map(followUp => 
                          '<div class="py-4">' +
                          '<p class="font-medium">Date: ' + followUp.date + '</p>' +
                          '<p class="text-gray-600">Action: ' + followUp.action + '</p>' +
                          '<p class="text-gray-600">Description: ' + followUp.description + '</p>' +
                          '<p class="text-gray-600">Next Step: ' + followUp.next_step + '</p>' +
                          '<p class="text-gray-600">Status: ' + followUp.status + '</p>' +
                          '<p class="text-gray-600">Priority: ' + followUp.priority + '</p>' +
                          '</div>'
                        ).join('') +
                        '</div>' +
                        '</div>' : ''}

                      \${reportData.notes ? 
                        '<div class="space-y-2">' +
                        '<h2 class="text-xl font-semibold">Notes</h2>' +
                        '<p class="whitespace-pre-wrap text-gray-600">' + reportData.notes + '</p>' +
                        '</div>' : ''}
                    </div>
                  </div>
                \`;
                
                reportDiv.innerHTML = content;
                window.print();
              </script>
            </body>
          </html>
        `);
        printWindow.document.close();
      }

      toast({
        title: "Success",
        description: "Report generated successfully",
      });
    } catch (error) {
      console.error("Error generating report:", error);
      toast({
        title: "Error",
        description: "Failed to generate report",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-6 space-y-6">
      <div className="space-y-4">
        <CategorySelector
          category={category}
          onCategoryChange={handleCategoryChange}
        />

        {category === "trade_show" && tradeShows.length > 0 && (
          <div className="space-y-2">
            <label className="text-sm font-medium">Select Trade Show</label>
            <Select
              value={selectedTradeShow?.toString()}
              onValueChange={(value) => setSelectedTradeShow(Number(value))}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a trade show" />
              </SelectTrigger>
              <SelectContent>
                {tradeShows.map((show) => (
                  <SelectItem key={show.id} value={show.id.toString()}>
                    {show.name} - {show.date}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        <IndustrySelector
          industry={industry}
          industries={industries}
          onIndustryChange={setIndustry}
        />

        <CompanySelector
          companies={companies}
          selectedCompanies={selectedCompanies}
          onCompanyToggle={handleCompanyToggle}
        />

        <ReportTitleInput
          title={reportTitle}
          onTitleChange={setReportTitle}
          category={category}
        />

        {category === "dashboard" && (
          <FollowUpDateRange
            includeFollowUps={includeFollowUps}
            dateRange={followUpDateRange}
            onIncludeFollowUpsChange={setIncludeFollowUps}
            onDateRangeChange={setFollowUpDateRange}
          />
        )}

        <div className="space-y-2">
          <Label>Notes</Label>
          <Textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter any notes for the report..."
            className="min-h-[100px]"
          />
        </div>

        <ReportGenerateButton
          onGenerateReport={generateReport}
          isDisabled={
            !category ||
            !industry ||
            selectedCompanies.length === 0 ||
            (category === "trade_show" && !selectedTradeShow) ||
            (category === "dashboard" &&
              includeFollowUps &&
              (!followUpDateRange.from || !followUpDateRange.to))
          }
        />
      </div>
    </Card>
  );
};

export default ReportForm;

